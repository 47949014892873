import React from "react";
import InputMask from "react-input-mask";
import styled from "styled-components";
import "./InputWithMaskStyles.scss";
import { Stack } from "../../ui-kit/layouts/Stack";
import { DText14 } from "../../ui-kit/typography/Typography";

const StyledStack = styled(Stack)`
  width: 100%;
`;

export const InputWithMask = ({
  value,
  onChange,
  mask,
  placeHolder,
  label,
  error,
  minLength,
}) => {
  return (
    <StyledStack space="5px">
      {label && label.length > 0 ? (
        <DText14 style={{ color: error ? "#ed0a34" : "#94A0B1" }}>
          {label}
        </DText14>
      ) : null}
      <InputMask
        className="input-with-mask"
        maskChar={""}
        minLength={minLength}
        value={value}
        onChange={onChange}
        mask={mask}
        placeholder={placeHolder}
        maskplaceholder={placeHolder}
      />
    </StyledStack>
  );
};
