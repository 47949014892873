import { RECEIVE_NOTIFICATION } from "../Actions/chat/ActionsNotifications";

export const NotificationReducer = (login = null, action) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATION:
      try {
        return JSON.parse(action.payload);
      } catch (error) {
        console.log(error);
        return login;
      }

    default:
      return login;
  }
};
