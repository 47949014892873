export const mapBy = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: false,
    };
  }, initialValue);
};

export const mapByWithFileds = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: {
        ...item,
        active: true,
      },
    };
  }, initialValue);
};

export const nativeMapBy = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const cascadeMapBy = (array, key, secondKey) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key][secondKey]]: item,
    };
  }, initialValue);
};
