import React, { useContext } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AsideToggleContext } from "../../PersonalArea/context/AsideToggleContext";
import "./styles/Navbar.scss";
import { useState } from "react";
import { ButtonWithBadge } from "../../../ui-kit/button-with-badge/ButtonWithBadge";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { startNotificationListening } from "../../../Actions/chat/ActionsNotifications";

const initalUserState = {
  ceo_name: "F E",
  avatar_url: "",
};

const PersonalAreaNavbar = ({
  authUser = initalUserState,
  advertismentTotal,
  startNotificationListening,
  notificationList,
}) => {
  const { toggleFunction } = React.useContext(AsideToggleContext);
  const navigate = useNavigate();
  const [creatorState, setCreatorState] = useState();
  const [adCount, setAdCount] = useState({ 1: 0 });

  const [notionsCount, setNotionsCount] = useState(0);
  useEffect(() => {
    setAdCount(advertismentTotal);
  }, [advertismentTotal]);

  useEffect(() => {
    setCreatorState(authUser);
  }, [authUser]);

  useEffect(() => {
    startNotificationListening();
  }, []);

  useEffect(() => {
    notificationList && setNotionsCount(notificationList.unread_chats_count);
  }, [notificationList]);

  return (
    <div>
      <div className="personal-area-header">
        <div className="personal-area-header-wrapper">
          <button className="button_aside-toggler" onClick={toggleFunction}>
            <img src="images/icons/ui/actions/menu-list.svg" alt="burger" />
          </button>
          <ButtonWithBadge
            variant="icon"
            className="button_chat"
            onClick={() => navigate("/chat")}
            count={Number(notionsCount)}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5371 2.55176C10.17 2.18847 13.83 2.18847 17.4629 2.55176L18.7752 2.68299C20.1391 2.81938 21.2273 3.88106 21.3973 5.24118L21.4216 5.43581C21.8006 8.46713 21.8006 11.5338 21.4216 14.5652C21.2477 15.9564 20.0651 17.0005 18.663 17.0005H13H12.638L12.3598 17.2323L8.00001 20.8654L8.00001 18.0005V17.0005H7.00001H5.33707C3.93497 17.0005 2.75229 15.9564 2.57838 14.5652C2.19946 11.5338 2.19946 8.46713 2.57838 5.43581L2.6027 5.24118C2.77272 3.88106 3.86093 2.81938 5.22484 2.68299L6.5371 2.55176ZM0.59382 5.18774C0.194313 8.3838 0.194313 11.6172 0.59382 14.8132C0.892836 17.2054 2.92632 19.0005 5.33707 19.0005H6.00001V20.8654C6.00001 22.5611 7.97772 23.4874 9.28038 22.4019L13.3621 19.0005H18.663C21.0737 19.0005 23.1072 17.2054 23.4062 14.8132C23.8057 11.6172 23.8057 8.3838 23.4062 5.18774L23.3819 4.99311C23.0961 2.70681 21.2668 0.922181 18.9742 0.692914L17.6619 0.561689C13.8967 0.185166 10.1033 0.185166 6.33809 0.561689L5.02584 0.692914C2.73317 0.922181 0.903937 2.70681 0.618148 4.99311L0.59382 5.18774ZM8.00001 11.0005C8.55229 11.0005 9.00001 10.5528 9.00001 10.0005C9.00001 9.4482 8.55229 9.00048 8.00001 9.00048C7.44772 9.00048 7.00001 9.4482 7.00001 10.0005C7.00001 10.5528 7.44772 11.0005 8.00001 11.0005ZM12 11.0005C12.5523 11.0005 13 10.5528 13 10.0005C13 9.4482 12.5523 9.00048 12 9.00048C11.4477 9.00048 11 9.4482 11 10.0005C11 10.5528 11.4477 11.0005 12 11.0005ZM17 10.0005C17 10.5528 16.5523 11.0005 16 11.0005C15.4477 11.0005 15 10.5528 15 10.0005C15 9.4482 15.4477 9.00048 16 9.00048C16.5523 9.00048 17 9.4482 17 10.0005Z"
                  fill="#212121"
                />
              </svg>
            }
          ></ButtonWithBadge>
          {/* <ButtonWithBadge
            variant="icon"
            className="button_chat"
            onClick={() => navigate("/chat")}
            count={Number(notionsCount)}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_10007_6429)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 1V0L11 0V1V3.06189C7.05369 3.55399 4 6.92038 4 11L4 16.1875C4 16.6913 3.98692 17.1856 3.83022 17.5529C3.76256 17.7115 3.68356 17.8048 3.59559 17.8643C3.50962 17.9225 3.3394 18 3 18C2.44772 18 2 18.4477 2 19C2 19.5523 2.44772 20 3 20L8 20C8 22.2091 9.79086 24 12 24C14.2091 24 16 22.2091 16 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18C20.6606 18 20.4904 17.9225 20.4044 17.8643C20.3164 17.8048 20.2374 17.7115 20.1698 17.5529C20.0131 17.1856 20 16.6913 20 16.1875L20 11C20 6.92038 16.9463 3.55399 13 3.06189V1ZM11.9958 5H12.0042C15.316 5.00226 18 7.68769 18 11V16.1875L18 16.2336C17.9996 16.6192 17.9989 17.3186 18.2075 18L5.79253 18C6.00111 17.3186 6.00042 16.6191 6.00003 16.2336L6 16.1875L6 11C6 7.68769 8.68403 5.00226 11.9958 5ZM12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22Z"
                    fill="#212121"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10007_6429">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
          ></ButtonWithBadge> */}

          {!!creatorState?.avatar_url &&
          creatorState?.avatar_url?.length > 0 ? (
            <div
              className="user-profile-icon"
              onClick={() => navigate("/profile")}
              style={{
                backgroundImage: `url('${creatorState?.avatar_url}')`,
                backgroundSize: "auto 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            ></div>
          ) : (
            <div
              className="user-profile-icon"
              style={{
                backgroundColor: "#7027C2",
              }}
              onClick={() => navigate("/profile")}
            >
              <span>
                {creatorState?.ceo_name
                  ? `${
                      creatorState?.ceo_name.split(" ")[0]?.split("")?.[0] || ""
                    }${
                      creatorState?.ceo_name.split(" ")[1]?.split("")?.[0] || ""
                    }`
                  : null}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    advertismentTotal: state.advertismentTotalReducer,
    notificationList: state.notificationList,
  };
};

const mapDispatchToProps = {
  startNotificationListening,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAreaNavbar);
