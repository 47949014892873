import AuthService from "../services/auth.service";

const initialState = {
  token: "",
};

const AuthorizationReducer = (login = initialState, action) => {
  switch (action.type) {
    case "POST_LOGIN":
      if (action.success) {
        if (action?.login?.data) {
          return { ...action.login.data, success: true };
        } else {
          return { success: true, message: action.message };
        }
      } else {
        return { success: false, message: action.message };
      }
    case "POST_LOGOUT":
      return { success: false };
    default:
      return login;
  }
};

export const AuthUserReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_AUTH_USER":
      return action.user.data;
    case "PATCH_USER":
      return action.user.data;
    case "POST_LOGOUT":
      AuthService.logout();
      return [];
    default:
      return state;
  }
};

export { AuthorizationReducer };
