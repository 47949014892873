const changeArray = (arr) => {
  const firstArray = [];
  const newArray = arr.sort((a, b) => a.title.localeCompare(b.title));
  const res = newArray.filter((elem, index) => {
    if (elem.id === 377 || elem.id === 368) {
      firstArray.push(elem);
      return false;
    }
    return true;
  });
  return [...firstArray, ...res];
};

export const CitiesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_CITIES":
      return changeArray(action.cities.data.attributes);
    default:
      return state;
  }
};

export const CountriesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_COUNTRIES":
      return action.countries.data.attributes;
    default:
      return state;
  }
};
