import axios from "axios";
import { bloggerUrl } from "./consts";

export const postLogin = (datas) => {
  var bodyFormData = new FormData();
  bodyFormData.append("username", datas.username);
  bodyFormData.append("password", datas.password);
  return async (dispatch) => {
    await axios
      .post(`${bloggerUrl}/api/auth/jwt/login`, bodyFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((login) => {
        dispatch({
          type: "POST_LOGIN",
          login,
          success: true,
          message: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch({
            type: "POST_LOGIN",
            success: true,
            message: error?.response?.data?.detail,
          });
        } else {
          dispatch({
            type: "POST_LOGIN",
            success: false,
            message: error?.response?.data?.detail,
          });
        }
        console.log(error);
      });
  };
};

export const getAuthUser = (id) => {
  return async (dispatch) => {
    await axios
      .get(`${bloggerUrl}/api/users/${id}`)
      .then((user) => {
        dispatch({
          type: "GET_AUTH_USER",
          user,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const postLogout = () => {
  return async (dispatch) => {
    dispatch({
      type: "POST_LOGOUT",
    });
  };
};
