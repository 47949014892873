const initialPlaylists = {
  list: [],
  pagination: {
    max_elements: 0,
    max_pages: 0,
    page: 0,
    limit: 0,
  },
};

export const PlaylistReducer = (state = initialPlaylists, action) => {
  switch (action.type) {
    case "GET_PLAYLIST": {
      const data = action.playlist.data;
      return {
        list: data.items,
        pagination: {
          max_elements: data.total,
          max_pages: data.pages,
          page: data.page,
          limit: data.size,
        },
      };
    }

    case "GET_CURRENT_PLAYLIST":
      const data = action.playlist.data;
      let array = [...state.list];
      const playlistIdx = array.findIndex((elem) => elem.id === data.id);
      array[playlistIdx] = data;
      return { ...state, list: array };

    case "POST_PLAYLIST":
      let timeArray = [...state.list];

      timeArray.push(action.playlist.data);

      return { ...state, list: timeArray };

    case "PUT_PLAYLIST":
      const newArray = state.list.map((elem) => {
        if (elem.id === action.playlist.data.id) {
          return action.playlist.data;
        } else return elem;
      });
      return {
        ...state,
        list: newArray,
      };

    case "DELETE_PLAYLIST_VIDEO":
      playList = action.playlist;
      currentPlaylistIdx = state.findIndex((elem) => elem.id === playList.id);
      if (currentPlaylistIdx < 0) {
        return state;
      }
      playlists = state.slice(0);
      const result = playlists[currentPlaylistIdx].playlist_entries.filter(
        (elem) => elem.id !== playList.video
      );
      playlists[currentPlaylistIdx].playlist_entries = result;
      return playlists;
    case "DELETE_PLAYLIST":
      const filtererState = state.list.filter(
        (item, index) => item.id !== action.id
      );
      return {
        ...state,
        list: filtererState,
      };
    default:
      return state;
  }
};
