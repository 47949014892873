const getLocalRefreshToken = () => {
  const refresh = localStorage.getItem("refresh");
  return refresh;
};

const getLocalAccessToken = () => {
  const access = localStorage.getItem("access");
  return access;
  // return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem("access", token);
};

const removeTokens = () => {
  localStorage.removeItem("refresh");
  localStorage.removeItem("access");
};
const getUser = () => {
  return localStorage.getItem("creator_id");
};

const setUser = (user) => {
  localStorage.setItem("creator_id", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("creator_id");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  removeTokens,
};

export default TokenService;
