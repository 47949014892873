import axios from "axios";
import { bloggerUrl } from "./consts";
import * as qs from "qs";

export const getReviewAdTotal = () => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: { size: 1, page: 1, statuses: "under_consideration" },
        // params: { size: 1, page: 1 },
      })
      .then((ad) => {
        dispatch({
          type: "GET_REVIEW_AD_TOTAL",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getBlockedAdTotal = () => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: { size: 1, page: 1, statuses: "rejected" },
      })
      .then((ad) => {
        dispatch({
          type: "GET_BLOCKED_AD_TOTAL",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getActiveAdTotal = () => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: { size: 1, page: 1, statuses: "active,inactive" },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((ad) => {
        dispatch({
          type: "GET_ACTIVE_AD_TOTAL",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllReviewAd = (arg) => {
  if (!!arg?.filters && arg?.filters !== null) {
    for (let key in arg.filters) {
      arg = { ...arg, [key]: arg.filters[key] };
    }
    delete arg.filters;
  }
  if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
    arg = { ...arg, order_by: `-${arg.order_by}` };
  delete arg.reversed_;
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: { ...arg, statuses: "under_consideration" },
      })
      .then((ad) => {
        dispatch({
          type: "GET_ALL_REVIEW_AD",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllBlockedAd = (arg) => {
  if (!!arg?.filters && arg?.filters !== null) {
    for (let key in arg.filters) {
      arg = { ...arg, [key]: arg.filters[key] };
    }
    delete arg.filters;
  }
  if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
    arg = { ...arg, order_by: `-${arg.order_by}` };
  delete arg.reversed_;
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: { ...arg, statuses: "rejected" },
      })
      .then((ad) => {
        dispatch({
          type: "GET_ALL_BLOCKED_AD",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllActiveAd = (arg) => {
  if (!!arg?.filters && arg?.filters !== null) {
    for (let key in arg.filters) {
      arg = { ...arg, [key]: arg.filters[key] };
    }
    delete arg.filters;
  }
  if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
    arg = { ...arg, order_by: `-${arg.order_by}` };
  delete arg.reversed_;
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/order-products`, {
        params: {
          ...arg,
          statuses: arg.statuses ? arg.statuses : "active,inactive",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((ad) => {
        dispatch({
          type: "GET_ALL_ACTIVE_AD",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getExternalAd = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${bloggerUrl}/api/ad-order/orders/${id}`)
      .then((ad) => {
        dispatch({
          type: "GET_EXTERNAL_AD",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const patchExternalAd = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .patch(`${bloggerUrl}/api/ad-order/order-products/${id}`, data)
      .then((ad) => {
        dispatch({
          type: "PATCH_EXTERNAL_AD",
          ad,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
