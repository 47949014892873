import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ user_id }) => {
  return (!!user_id && user_id.length > 0) ||
    (localStorage.getItem("access")?.length > 0 &&
      !!localStorage.getItem("creator_id")) ? (
    <Outlet />
  ) : (
    <Navigate to="/sign_in" />
  );
};

export { PrivateRoute };
