import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${(props) => (props.full ? "100%" : "auto")};
  border-radius: 80px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  padding: 0px;
  width: 24px;
  height: 24px;
  &:hover {
    svg {
      path {
        fill: #7027c2;
      }
    }
  }
  background: transparent;
`;

const StyledBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;

  position: absolute;
  right: -10px;
  top: -5px;
  min-width: 19px;
  height: 19px;
  background: #7027c2;
  border-radius: 80px;

  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const ButtonWithBadge = ({
  onClick,
  className,
  variant,
  size,
  disabled = false,
  icon,
  count = 0,
}) => {
  return (
    <StyledButton
      className={`${variant} ${className} ${size}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {count ? <StyledBadge>{count}</StyledBadge> : null}
    </StyledButton>
  );
};
