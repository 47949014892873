const initialVideoGadgetGraph = {
  day: [[], [], [], []],
  week: [[], [], [], []],
  month: [[], [], [], []],
  year: [[], [], [], []],
  full: [[], [], [], []],
};
const initialVideoGadgetStatistic = {
  day: {
    mobile: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    desktop: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tv: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tablet: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    total_watch_time: 0,
    amount: 0,
  },
  week: {
    mobile: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    desktop: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tv: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tablet: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    total_watch_time: 0,
    amount: 0,
  },
  month: {
    mobile: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    desktop: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tv: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tablet: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    total_watch_time: 0,
    amount: 0,
  },
  year: {
    mobile: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    desktop: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tv: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tablet: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    total_watch_time: 0,
    amount: 0,
  },
  full: {
    mobile: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    desktop: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tv: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    tablet: {
      total_watch_time: 0,
      average_session_time: 0,
      amount: 0,
    },
    total_watch_time: 0,
    amount: 0,
  },
};

export const VideoViewGadgetGraphReducer = (
  state = initialVideoGadgetGraph,
  action
) => {
  switch (action.type) {
    case "GET_VIDEO_GADGETS_GRAPH":
      const data = action;
      return {
        ...state,
        [data.period]: data.views,
      };
    default:
      return state;
  }
};

export const VideoViewGadgetStatisticReducer = (
  state = initialVideoGadgetStatistic,
  action
) => {
  switch (action.type) {
    case "RESET_GADGET_STATISTIC":
      return initialVideoGadgetStatistic;
    case "GET_VIDEO_GADGETS_STATISTIC":
      const data = action;
      let resultState =
        data.key === "total_watch_time"
          ? {
              ...state,
              [data.period]: {
                ...state[data.period],
                total_watch_time:
                  state[data.period].total_watch_time + data.views,
              },
            }
          : data.key === "amount"
          ? {
              ...state,
              [data.period]: {
                ...state[data.period],
                amount: state[data.period].amount + data.views,
              },
            }
          : state;
      return {
        ...resultState,
        [data.period]: {
          ...resultState[data.period],
          [data.device_type]: {
            ...resultState[data.period][data.device_type],
            [data.key]: data.views,
          },
        },
      };
    default:
      return state;
  }
};
