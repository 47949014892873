export const GenresReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_GENRES":
      const data = action.genre.data;
      return data;

    default:
      return state;
  }
};
export const VideoCaregoryReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_VIDEO_CATEGORY":
      const data = action.genre.data;
      const value = data.sort((a, b) => a.title.localeCompare(b.title));
      return value;

    default:
      return state;
  }
};
