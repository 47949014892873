import React, { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui-kit/button/Button";
import { H2Text } from "../../ui-kit/typography/Typography";

const Wrapper = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
  padding: 168px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .not-found-page__title {
    margin: 40px 0 24px 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
  }
`;

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Wrapper className="not-found-page ">
      <svg
        width="468"
        height="208"
        viewBox="0 0 468 208"
        className="block"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.2072 206.859H108.496V177.901H132.038V148.175H108.496V30.8086H80.6044L0 155.863V177.901H74.2072V206.859ZM37.8713 149.713L76.5102 87.1856V149.713H37.8713Z"
          fill="#3586FF"
        />
        <path
          d="M410.17 206.859H444.458V177.901H468V148.175H444.458V30.8086H416.567L335.962 155.863V177.901H410.17V206.859ZM373.834 149.713L412.473 87.1856V149.713H373.834Z"
          fill="#3586FF"
        />
        <circle cx="234" cy="119.567" r="88.0251" fill="#3586FF" />
        <circle cx="288.282" cy="53.5486" r="53.5486" fill="#FD4AA7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.377 31.7041C277.906 34.0259 309.75 61.1305 319.181 97.2886C310.451 103.467 299.791 107.097 288.282 107.097C258.708 107.097 234.734 83.1229 234.734 53.5488C234.734 45.7681 236.393 38.375 239.377 31.7041Z"
          fill="#7027C2"
        />
      </svg>

      <H2Text className="not-found-page__title">Страница не найдена</H2Text>
      <Button
        text="Перейти на главную"
        variant="primary"
        onClick={() => navigate("/")}
      />
    </Wrapper>
  );
};

export default NotFoundPage;
