import styled from "styled-components";

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ stretch }) => stretch && "height: 100%;"}
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: ${({ space }) => space};
  }
`;
