import React, { useContext } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Stack } from "../../../ui-kit/layouts/Stack";
import {
  H5Text,
  DTBold13,
  DText15,
} from "../../../ui-kit/typography/Typography";
import { Input } from "../../../ui-kit/input/Input";
import { Button } from "../../../ui-kit/button/Button";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import { initialUser } from "../initial";
import { bloggerUrl } from "../../../Actions/consts";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import { SuccessRegisterModal } from "../SuccessRegisterModal.jsx";

const ErrorDetail = styled(Cluster)``;

const Checkbox = styled.div`
  cursor: pointer;
  display: block;
  font-size: 15px;
  height: 24px;
  line-height: 24px;
  position: relative;
  &::before {
    background: #f4f4f8;
    border: 1px solid #d1d8e1;
    border-radius: 4px;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: calc(50% - 12px);
    width: 24px;
  }
  margin-right: 24px;
  &.selected {
    &::before {
      background: #005bff url("images/icons/ui/forms/white/check.svg") no-repeat
        50% 50%;
      border-color: #005bff;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #94a0b1;
`;

const ThirdRegStep = ({ userState = initialUser, setUserState, setActive }) => {
  const [errorDetail, setErrorDetail] = useState("");
  const navigate = useNavigate();

  const [policyState, setPolicyState] = useState(false);
  const [ofertState, setOfertState] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const handleInputChange = (e, key, secondKey) => {
    e.preventDefault();
    const value = e.target.value;
    if (secondKey) {
      setUserState({
        ...userState,
        [key]: { ...userState[key], [secondKey]: value },
      });
    } else {
      setUserState({
        ...userState,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    if (!userState.password) {
      setActive(2);
    }
  }, [userState]);

  const handleRegisterClick = () => {
    axios
      .post(`${bloggerUrl}/api/auth/register`, userState, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((login) => {
        setErrorDetail("");
        navigate("/sign-in");
        handleModal(<SuccessRegisterModal />);
      })
      .catch((error) => {
        if (
          error.response.status === 400 &&
          error.response &&
          error.response?.data?.detail
        ) {
          setErrorDetail(error.response?.data?.detail);
        } else {
          setErrorDetail(
            "Что-то пошло не так:( Проверьте правильность введеныых данных"
          );
        }
        console.log(error);
      });
  };

  return (
    <Stack space="32px">
      <Stack space="16px">
        <H5Text>Банковские реквизиты</H5Text>
        <Stack space="24px">
          <Input
            label="ИНН*"
            value={userState?.bank_inn}
            name="inn"
            type="number"
            onChange={(e) =>
              e.target.value?.length <= 10 && handleInputChange(e, "bank_inn")
            }
          />
          <Input
            label="КПП"
            value={userState?.kpp}
            type="number"
            onChange={(e) =>
              e.target.value?.length <= 9 && handleInputChange(e, "kpp")
            }
          />
          <Cluster space="16px">
            <Input
              label="Расчетный счет*"
              value={userState?.checking_account}
              type="number"
              onChange={(e) =>
                e.target.value?.length <= 20 &&
                handleInputChange(e, "checking_account")
              }
            />
            <Input
              label="Банк*"
              value={userState?.bank_name}
              type="text"
              onChange={(e) => handleInputChange(e, "bank_name")}
            />
          </Cluster>
          <Cluster space="16px">
            <Input
              label="Корреспондентский счет*"
              type="number"
              value={userState.correspondent_account}
              onChange={(e) =>
                e.target.value?.length <= 20 &&
                handleInputChange(e, "correspondent_account")
              }
            />
            <Input
              label="БИК*"
              type="number"
              value={userState.bik}
              onChange={(e) =>
                e.target.value?.length <= 9 && handleInputChange(e, "bik")
              }
            />
          </Cluster>
        </Stack>
      </Stack>
      {errorDetail.length > 0 ? (
        <ErrorDetail align="center" justify="center">
          <DTBold13 style={{ color: "#ed0a34" }}>{errorDetail}</DTBold13>
        </ErrorDetail>
      ) : null}
      <Stack space="24px">
        <Cluster justify="space-between" style={{ marginTop: "32px" }}>
          <Button
            size="big"
            variant="secondary"
            onClick={() => setActive(2)}
            text="Назад"
          />
          <Button
            size="big"
            variant="primary"
            disabled={
              !policyState ||
              !ofertState ||
              userState.bik.length !== 9 ||
              !userState.bank_name.length ||
              userState.correspondent_account.length !== 20 ||
              userState.checking_account.length !== 20 ||
              userState.bank_inn.length !== 10 ||
              (userState.kpp.length > 0 && userState.kpp.length !== 9)
            }
            onClick={() => handleRegisterClick()}
            text="Зарегистрироваться"
          />
        </Cluster>
        <Stack space="11px">
          <Cluster space="12px">
            <Checkbox
              className={ofertState ? "selected" : ""}
              onClick={() => setOfertState(!ofertState)}
            />
            <Cluster space="6px">
              <DText15 style={{ color: "#94A0B1" }}>
                Я согласен с условиями
              </DText15>
              <StyledLink to="/policy" target="_blank">
                политики конфиденциальности
              </StyledLink>
            </Cluster>
          </Cluster>
          <Cluster space="12px">
            <Checkbox
              className={policyState ? "selected" : ""}
              onClick={() => setPolicyState(!policyState)}
            />
            <Cluster space="6px">
              <DText15 style={{ color: "#94A0B1" }}>Я принимаю условия</DText15>
              <StyledLink to="/legal" target="_blank">
                пользовательского соглашения — оферты
              </StyledLink>
            </Cluster>
          </Cluster>
        </Stack>
      </Stack>
    </Stack>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     login: state.login,
//   };
// };

// const mapDispatchToProps = {
//   postLogin,
// };

export default connect(null, null)(ThirdRegStep);
