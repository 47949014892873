const initialTips = {
	list: [],
	pagination: {},
};

export const BrightboxesReducer = (state = initialTips, action) => {
	switch (action.type) {
		case "GET_BRIGHTBOXES":
			const data = action.brightboxes.data;
			return {
				list: data?.tips || [],
				pagination: {
					max_elements: data.paginate_?.total_rows || 0,
					max_pages: data.paginate_?.total_pages || 0,
					page: data.paginate_?.page_in?.page || 0,
					limit: data.paginate_?.offset_in?.limit || 0,
					sum: data?.total_revenue || 0,
				},
			};
		default:
			return state;
	}
};
