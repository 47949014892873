import api from "./api";
import TokenService from "./token.service";

const logout = () => {
  TokenService.removeUser();
  TokenService.removeTokens();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  // register,
  // login,
  logout,
  getCurrentUser,
};

export default AuthService;
