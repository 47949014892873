import styled from "styled-components";

export const H1Text = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 115%;
`;
export const H2Text = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 115%;
`;
export const H3Text = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 115%;
`;
export const H4Text = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
`;
export const H5Text = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
`;

export const DText15 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
`;
export const DText14 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
`;
export const DText13 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
`;

export const DTList15 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
`;
export const DTList14 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;
export const DTList13 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
`;
export const DTBold15 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
`;
export const DTBold14 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
`;
export const DTBold13 = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
`;

export const Sup = styled.div`
  color: #94a0b1;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 8px;
  position: relative;
  vertical-align: top;
`;
