import { compareSort } from "./reducersScript";

const initialVideos = {
  list: [],
  pagination: {
    max_elements: 0,
    max_pages: 0,
    page: 0,
    limit: 0,
  },
};

export const VideosReducer = (video = initialVideos, action) => {
  switch (action.type) {
    case "GET_VIDEOS":
      const data = action.video.data;
      return {
        list: data.items,
        pagination: {
          max_elements: data.total,
          max_pages: data.pages,
          page: data.page,
          limit: data.size,
        },
      };

    case "PUT_VIDEO_LIST":
      return {
        ...video,
        list: (video.list = video.list.map((item, index) =>
          item.id === action.video.id
            ? { ...item, is_active: action.video.is_active }
            : item
        )),
      };
    case "POST_CURRENT_VIDEO":
      const videos = [...video.list];
      videos.push(action.video.data);
      return {
        ...video,
        list: videos,
      };
    case "PATCH_CURRENT_VIDEO":
      return {
        ...video,
        list: (video.list = video.list.map((item, index) =>
          item.id === action.video.data.id
            ? { ...item, is_active: action.video.data.is_active }
            : item
        )),
      };
    case "DELETE_CURRENT_VIDEO":
      return {
        ...video,
        list: video.list.filter((item, index) => item.id !== action.payload),
        pagination: {
          ...video.pagination,
          max_elements: video.pagination.max_elements,
        },
      };

    default:
      return video;
  }
};

export const VideoCurrentReducer = (video = {}, action) => {
  let timeArray = [];
  switch (action.type) {
    case "GET_CURRENT_VIDEO":
      if (action.video.data.movie_format?.id === 2) {
        timeArray = action.video.data?.childs;
        timeArray && timeArray.sort(compareSort);
        return { ...action.video.data, childs: timeArray };
      }
      return action.video.data;
    case "PUT_CURRENT_VIDEO":
      return action.video.data;
    case "PATCH_CURRENT_VIDEO":
      return action.video.data;
    case "POST_VIDEOS":
      return action.video.data;
    case "RESET_VIDEO":
      return [];
    default:
      return video;
  }
};
