import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { postLogin } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";
import {
  DTBold13,
  DText13,
  H4Text,
} from "../../ui-kit/typography/Typography.jsx";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../ui-kit/layouts/Stack.jsx";
import { Button } from "../../ui-kit/button/Button.jsx";
import { Link } from "react-router-dom";
import axios from "axios";
import { bloggerUrl } from "../../Actions/consts";

const Authorization = ({ login, postLogin }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(() => false);
  const [formFieldErrorState, setFormFieldErrorState] = useState(() => false);
  const [authState, setAuthState] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const handleAuthChange = (evt) => {
    // обработчик заполнение инпутов
    const value = evt.target.value; // присваимваем значение инпута
    setAuthState({ ...authState, [evt.target.name]: value }); //
  };

  const sendUser = () => {
    postLogin(authState);
  };

  useEffect(async () => {
    if (login?.success && !!login?.access_token && !!login?.refresh_token) {
      setFormFieldErrorState(false);
      await axios
        .get(`${bloggerUrl}/api/users/${String(login.user_id)}`, {
          headers: {
            Authorization: String(`Bearer ${login.access_token}`),
          },
        })
        .then((user) => {
          const res = user.data;
          if (res.application_status === "accepted") {
            dispatch({
              type: "GET_AUTH_USER",
              user,
            });
            localStorage.setItem("access", login.access_token);
            localStorage.setItem("refresh", login.refresh_token);
            localStorage.setItem("creator_id", String(login.user_id));
            setErrorMessage("");
            navigate("/");
          } else if (res.application_status === "not_accepted") {
            setErrorMessage(
              "Ваш аккаунт ещё на рассмотрении. Свяжитесь с администратором"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (login?.success !== undefined && !login?.success) {
      setFormFieldErrorState(true);
      setErrorMessage(login.message);
    } else {
      setFormFieldErrorState(false);
      setErrorMessage(login.message);
    }
  }, [login]);

  return (
    <section
      id="auth"
      onKeyPress={(e) =>
        e.key === "Enter" ? sendUser(formFieldErrorState) : null
      }
    >
      <div id="auth-form">
        <Stack>
          <img src="images/icons/main/logo.svg" alt="" id="logo" />
          <Cluster justify="center">
            <H4Text className="title">Вход в сервис</H4Text>
          </Cluster>
        </Stack>

        <div className="flex-block">
          <div className={`form-field ${formFieldErrorState ? "error" : ""}`}>
            <div className="form-field-label">Email</div>
            <div className="input-field">
              <input
                id="login-input"
                name="username"
                value={authState.email}
                onChange={(e) => handleAuthChange(e)}
              />
            </div>
            <div className="form-field-error">Проверьте правильность ввода</div>
          </div>
        </div>
        <div className="flex-block">
          <div className={`form-field ${formFieldErrorState ? "error" : ""}`}>
            <Cluster
              className="form-field-label"
              justify="space-between"
              style={{ display: "flex" }}
            >
              <span>Пароль</span>
              <Link to="/forgot_password">Забыли пароль?</Link>
            </Cluster>
            <div className="input-field">
              <input
                type={showPassword ? "text" : "password"}
                id="password-input"
                name="password"
                value={authState.password}
                onChange={(e) => handleAuthChange(e)}
              />
              <div
                className={`input-field-eye input-icon ${
                  showPassword ? "show-password" : ""
                }`}
                onClick={() => setShowPassword(!showPassword)}
              ></div>
            </div>
            <div className="form-field-error">Проверьте правильность ввода</div>
          </div>
        </div>
        {!formFieldErrorState ? (
          <DText13 style={{ color: "#ED0A34", width: "290px" }}>
            {errorMessage}
          </DText13>
        ) : null}

        <div className="flex-block">
          <div className="form-field">
            <Button
              size="big"
              full={true}
              variant="primary"
              onClick={() => sendUser(formFieldErrorState)}
              text="Войти"
            />
          </div>
        </div>
        <Cluster space="3px">
          <DText13 style={{ color: "#94A0B1" }}>
            Еще нет профиля в Atips? Тогда
          </DText13>
          <DTBold13
            style={{ marginTop: "0.12em" }}
            className="register-btn"
            onClick={() => navigate("/sign_up")}
          >
            Зарегистрируйтесь
          </DTBold13>
        </Cluster>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = {
  postLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
