import styled from "styled-components";

export const Cluster = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap};
  gap: ${({ space }) => space};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  position: relative;
`;
