import React, { useContext } from "react";
import { ModalContext } from "../modal/ModalContext/ModalContext.tsx";
import styled from "styled-components";

const ModalContentWrapper = styled.div`
  background: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  display: block;
  padding: 32px;
  position: relative;
  width: 461px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;
  padding: 8px;
`;

const SpanText = styled.div`
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #94a0b1;
`;

const Title = styled.span`
  font-size: 24px;
  display: inline-block;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 32px;
  padding-right: 72px;
  position: relative;
  margin-bottom: 32px;
`;

export const SuccessRegisterModal = () => {
  const { handleModal } = useContext(ModalContext);
  return (
    <ModalContentWrapper>
      <Title>Регистрация</Title>
      <CloseButton onClick={() => handleModal()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
            fill="#212121"
          ></path>
        </svg>
      </CloseButton>
      <SpanText>
        Вы успешно зарегистрировались. Пожалуйста дождитесь подтверждения
        аккаунта администратором.
      </SpanText>
    </ModalContentWrapper>
  );
};
