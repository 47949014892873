import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Stack } from "../../../ui-kit/layouts/Stack";
import styled from "styled-components";
import { DTList15, DText13 } from "../../../ui-kit/typography/Typography";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import axios from "axios";

const StyledStack = styled(Stack)`
  width: 100%;
`;

const StyledInput = styled.div`
  background: #f4f4f8;
  border-radius: 8px;
  position: relative;
  border: 1px solid #f4f4f8;
  border-radius: 8px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  height: 116px;
  color: #212121;
  width: 100%;
  padding: 16px 20px;
  outline: none;
  resize: none;
  input {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  ${DText13} {
  }
`;

const UploadImages = ({ uploadState, setUploadState }) => {
  const [prewDragState, setPrewDragState] = useState(() => false); // состояние drag для превьюшки
  const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
  const [loading, setLoading] = useState(false);
  const [fileState, setFileState] = useState();
  const dragStartHandler = (e) => {
    e.preventDefault();
    setPrewDragState(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setPrewDragState(false);
  };

  const onDropHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    let files = [...e.dataTransfer.files];
    let reader = new FileReader();
    let file = files[0];
    reader.onloadend = () => {
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios
        .post("https://atips.io/uploads/upload.php", bodyFormData)
        .then((res) => {
          setFileState(file);
          setUploadState(`https://atips.io${res?.data?.filename}`);
        })
        .finally(() => setLoading(false));
      setUploadFlagState(true);
    };
    reader.readAsDataURL(files[0]);
    setPrewDragState({ [key]: false });
  };

  const onPrewPhotoChange = (event, key) => {
    setLoading(true);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        var bodyFormData = new FormData();
        bodyFormData.append("file", file);
        axios
          .post("https://atips.io/uploads/upload.php", bodyFormData)
          .then((res) => {
            setFileState(file);
            setUploadState(`https://atips.io${res?.data?.filename}`);
          })
          .finally(() => setLoading(false));
        setUploadFlagState(true);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <StyledInput>
      <div
        onDragStart={(e) => dragStartHandler(e, "channel_preview")}
        onDragLeave={(e) => dragLeaveHandler(e, "channel_preview")}
        onDragOver={(e) => dragStartHandler(e, "channel_preview")}
        onDrop={(e) => onDropHandler(e, "channel_preview")}
        style={{ height: "100%" }}
      >
        {!uploadState && !loading ? (
          !prewDragState ? (
            <Stack space="auto" stretch={true}>
              <Cluster justify="space-between">
                <DTList15>
                  Выберите файл <br /> или перетащите его сюда
                </DTList15>
                <img
                  style={{ height: "24px" }}
                  src="/images/icons/ui/files/upload.svg"
                  alt="загрузить"
                />
              </Cluster>
              <DText13 style={{ color: "#94A0B1" }}>
                Файлы .jpg,.png. Макс. размер 5 Мб
              </DText13>
            </Stack>
          ) : (
            <Cluster align="center" justify="center" style={{ height: "100%" }}>
              <DTList15>Отпустите изображение</DTList15>
            </Cluster>
          )
        ) : loading ? (
          <Cluster align="center" justify="center" style={{ height: "100%" }}>
            <DTList15>Идет загрузка изображения...</DTList15>
          </Cluster>
        ) : fileState ? (
          <Cluster align="start" justify="start" style={{ height: "100%" }}>
            <DTList15>{fileState.name}</DTList15>
          </Cluster>
        ) : (
          <Cluster align="center" justify="center" style={{ height: "100%" }}>
            <DTList15>Произошла непредвиденная ошибка</DTList15>
          </Cluster>
        )}
        <input
          type="file"
          name="channel_preview"
          accept="image/jpg, image/jpeg, image/png, image/webp"
          onChange={(e) => onPrewPhotoChange(e, "channel_preview")}
        />
      </div>
    </StyledInput>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loadingStatus.loading,
  };
};

// const mapDispatchToProps = {
// 	deleteUser,
// };

export default connect(mapStateToProps, null)(UploadImages);
