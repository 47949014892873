import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalContext } from "../modal/ModalContext/ModalContext.tsx";
import { Button } from "../../ui-kit/button/Button.jsx";
import { postLogout } from "../../Actions/AuthorizationActions";

const UniversalLogoutModal = ({postLogout}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleModal, modal } = useContext(ModalContext);

  const handleLogoutClick = () => {
    postLogout()
    handleModal()
  };
  return (
    <>
      <div className="add-category-wrapper">
        <div className="modal-field-title">Вы уверены?</div>
        <div className="modal-field-close" onClick={() => handleModal()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
              fill="#212121"
            ></path>
          </svg>
        </div>
        <div className="modal-buttons delete">
          <Button
            onClick={() => handleModal()}
            text="Отменить"
            variant="secondary"
          />
          <Button
            className=""
            variant="primary"
            onClick={() => handleLogoutClick()}
            text="Выйти"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loadingStatus.loading,
  };
};

const mapDispatchToProps = {
	postLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UniversalLogoutModal);
