const initialSellers = {
  list: [],
  pagination: {},
};

export const SellersReducer = (state = initialSellers, action) => {
  switch (action.type) {
    case "GET_SELLERS":
      return {
        list: action.sellers.data.items,
        pagination: {
          max_elements: action.sellers.headers?.max_elements,
          max_pages: action.sellers.headers?.max_pages,
        },
      };
    default:
      return state;
  }
};
export const DashboardSellersReducer = (state = initialSellers, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_SELLERS":
      return {
        list: action.sellers.data,
      };
    default:
      return state;
  }
};
