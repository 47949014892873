import TokenService from "./token.service.js";
// import { refreshToken } from "../actions/auth.js";
import { bloggerUrl } from "../Actions/consts.js";
import AuthService from "./auth.service.js";
import axios from "axios";
import { appStore } from "../Store/Store";

export const setupInterceptors = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      // const token = localStorage.getItem("token");
      if (
        token &&
        config.url !== `${bloggerUrl}/api/auth/refresh` &&
        config.ignoreGlobalCatch !== true
      ) {
        config.headers["Content-Type"] = "application/json";
        config.headers["Authorization"] = String(`Bearer ${token}`);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== `${bloggerUrl}/api/auth/jwt/refresh` &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const refreshToken = TokenService.getLocalRefreshToken();
          const token = TokenService.getLocalAccessToken();
          try {
            const rs = await axios.post(
              `${bloggerUrl}/api/auth/jwt/refresh`,
              {
                refresh_token: refreshToken,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const { access_token } = rs.data;

            // dispatch(refreshToken(accessToken));
            TokenService.updateLocalAccessToken(access_token);

            return axios(originalConfig);
          } catch (_error) {
            appStore.dispatch({
              type: "POST_LOGOUT",
            });
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
