export const initialUser = {
  name: "",
  legal_name: "",
  description: "",
  email: "",
  ad_categories: [],
  socials: {
    vk: "",
    website: "",
    youtube: "",
    telegram: "",
  },
  ogrn: "",
  inn: "",
  bank_inn: "",
  kpp: "",
  ogrnip: "",
  bik: "",
  checking_account: "",
  bank_name: "",
  correspondent_account: "",
  legal_address: "",
  actual_address: "",
  ceo_name: "",
  contact_name: "",
  contact_phone_number: "",
  password: "",
  is_blocked: true,
};
