import React, { useContext } from "react";
import { useEffect } from "react";
import "./styles/Navbar.scss";
import { useState } from "react";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import { Button } from "../../../ui-kit/button/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DText15 } from "../../../ui-kit/typography/Typography";
import { VideoUploadContext } from "../../contexts/video-upload-context/VideoUploadContext";
import Lottie from "react-lottie-player";
import preloaderJson from "../../PersonalArea/preloader.json";

const Wrapper = styled.div`
  background: #ffffff;
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 32px 0 32px;
  width: 100%;
  align-items: center;
  height: 56px;
  margin-top: -56px;
  z-index: 1;
  &.other {
    position: absolute;
    &.open {
      margin-top: -0px;
      z-index: 3;
      transform: translateY(0px);
    }
  }
  &.open {
    margin-top: -0px;
    z-index: 3;
    margin-top: -8px;
    transform: translateY(8px);
  }
  /* transition: all 0.5s; */
`;
export const UploadStatusInfo = ({ isOpen, setOpen, other }) => {
  const {
    videoUploadState,
    setVideoUploadState,
    onVideoUpload,
    uploadVideoId,
  } = useContext(VideoUploadContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (uploadVideoId && !isOpen) {
      setOpen(true);
    }
  }, [uploadVideoId]);

  const handleClick = () => {
    if (videoUploadState.isReady) {
      setOpen(false);
    }
    navigate(`/edit-video?id=${uploadVideoId}`);
  };
  return (
    <>
      <Wrapper className={`${isOpen ? "open" : ""} ${other ? "other" : ""}`}>
        <Cluster
          align="center"
          justify="space-between"
          style={{ width: "100%" }}
        >
          <Cluster space="16px" align="center">
            {videoUploadState.isReady ? (
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_10707_86720)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.7071 10.7071L17.4142 10L16 8.58579L15.2929 9.29289L11 13.5858L8.70711 11.2929L8 10.5858L6.58579 12L7.29289 12.7071L10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071L16.7071 10.7071Z"
                      fill="#7027C2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_10707_86720">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <DText15 style={{ color: "#7027C2" }}>
                  Загрузка видео завершена!
                </DText15>
              </>
            ) : (
              <>
                <Lottie
                  loop
                  animationData={preloaderJson}
                  play
                  style={{ width: 24, height: 24 }}
                />
                <DText15 style={{ color: "#7027C2" }}>
                  Загрузка видео {Math.round(videoUploadState.progress)} %
                </DText15>
              </>
            )}
          </Cluster>
          {uploadVideoId ? (
            <Button
              variant="secondary"
              size="small"
              text="На страницу видео"
              onClick={() => handleClick()}
            />
          ) : null}
        </Cluster>
      </Wrapper>
    </>
  );
};
