import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { postLogin } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";
import {
  DTBold13,
  DText13,
  DText15,
  H4Text,
} from "../../ui-kit/typography/Typography.jsx";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../ui-kit/layouts/Stack.jsx";
import { Button } from "../../ui-kit/button/Button.jsx";
import { Input } from "../../ui-kit/input/Input";
import axios from "axios";
import { bloggerUrl } from "../../Actions/consts";
import { ForgotPasswordModal } from "./ForgotPasswordModal.jsx";
import { ModalContext } from "../modal/ModalContext/ModalContext.tsx";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [emailState, setEmailState] = useState("");
  const [loading, setLoading] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailState(value);
  };

  const handleClick = async () => {
    setLoading(true);
    await axios
      .post(`${bloggerUrl}/api/auth/forgot-password`, {
        email: emailState,
      })
      .then(() => {
        navigate("/sign_in");
        handleModal(<ForgotPasswordModal />);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section
      id="auth"
      onKeyPress={(e) =>
        e.key === "Enter" ? sendUser(formFieldErrorState) : null
      }
    >
      <div id="auth-form" style={{ paddingBottom: "26px" }}>
        <Stack>
          <img src="images/icons/main/logo.svg" alt="" id="logo" />
          <Cluster justify="center">
            <H4Text className="title">Восстановить пароль</H4Text>
          </Cluster>
        </Stack>
        <Stack space="24px">
          <DText15 style={{ marginTop: "16px" }}>
            Введите email, который вы указывали при регистрации.
          </DText15>
          <Input
            name="username"
            value={emailState}
            label="Email"
            onChange={(e) => handleInputChange(e)}
          />
          <Cluster>
            <Button
              className={loading ? "preloader" : ""}
              size="big"
              full={true}
              variant="primary"
              disabled={!emailState.length}
              onClick={handleClick}
              text="Восстановить пароль"
            />
          </Cluster>
          <Cluster space="3px" justify="center">
            <DText13 style={{ color: "#94A0B1" }}>
              Вспомнили пароль? Тогда
            </DText13>
            <DTBold13
              style={{ marginTop: "0.12em" }}
              className="register-btn"
              onClick={() => navigate("/sign_in")}
            >
              Войдите
            </DTBold13>
          </Cluster>
        </Stack>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = {
  postLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
