import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Stack } from "../../../ui-kit/layouts/Stack";
import { H5Text } from "../../../ui-kit/typography/Typography";
import { Input } from "../../../ui-kit/input/Input";
import { Button } from "../../../ui-kit/button/Button";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import { initialUser } from "../initial";
import { useDebounce } from "../../PersonalArea/scripts/useDebounse";
import { isValidUrl } from "../../PersonalArea/scripts/PersonalAreaScripts";

const EyeBtn = styled.div`
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  bottom: 18px;
  right: 16px;
  pointer-events: none;
  background: url("images/icons/ui/forms/eye_off.svg");
  background-size: contain;
  cursor: pointer;
  pointer-events: all;
  opacity: 0.4;
  &.show-password {
    background: url("images/icons/ui/forms/eye.svg");
    background-size: contain;
    opacity: 1;
  }
`;

const SecondRegStep = ({
  userState = initialUser,
  setUserState,
  setActive,
}) => {
  const [errorState, setErrorState] = useState({
    vk: false,
    telegram: false,
    website: false,
    youtube: false,
  });

  const [firstShowPassword, setFirstShowPassword] = useState(false);
  const [secondShowPassword, setSecondShowPassword] = useState(false);
  const [firstPasswordState, setFirstPasswordState] = useState(
    userState?.password
  );
  const [secondPasswordState, setSecondPasswordState] = useState(
    userState?.password
  );

  const secondPass = useDebounce(() => secondPasswordState, 500);

  const handleInputChange = (e, key, secondKey) => {
    e.preventDefault();
    const value = e.target.value;
    if (secondKey) {
      setUserState({
        ...userState,
        [key]: {
          ...userState[key],
          [secondKey]: value,
        },
      });
      // валидация урла
      // const url = isValidUrl(value);
      const url = true;

      if (!url && value.length !== 0)
        setErrorState({ ...errorState, [secondKey]: true });
      else setErrorState({ ...errorState, [secondKey]: false });
    } else {
      setUserState({
        ...userState,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    if (!secondPass.length) return;

    if (secondPass === firstPasswordState) {
      setUserState({
        ...userState,
        password: secondPass,
      });
      setErrorState({ ...errorState, password: false });
    } else {
      setErrorState({ ...errorState, password: true });
      setUserState({
        ...userState,
        password: "",
      });
    }
  }, [secondPass, firstPasswordState]);

  const handleNextClick = () => {
    const socials = { ...userState.socials };
    const telegram = socials.telegram.length
      ? "https://" + socials.telegram.replace("https://", "")
      : "";
    const vk = socials.vk.length
      ? "https://" + socials.vk.replace("https://", "")
      : "";
    const youtube = socials.youtube.length
      ? "https://" + socials.youtube.replace("https://", "")
      : "";
    const website = socials.website.length
      ? "https://" + socials.website.replace("https://", "")
      : "";
    setUserState({ ...userState, socials: { telegram, vk, youtube, website } });
    setActive(3);
  };

  return (
    <Stack space="24px">
      <Stack space="16px">
        <H5Text>Аккаунт</H5Text>
        <Input
          label="Email для входа*"
          type="text"
          name="mail"
          value={userState?.email}
          onChange={(e) => handleInputChange(e, "email")}
          placeholder="example@atips.ru"
        />
        <div style={{ position: "relative" }}>
          <Input
            label="Придумайте пароль*"
            type={firstShowPassword ? "text" : "password"}
            name="password"
            error={"password" in errorState && errorState.password}
            value={firstPasswordState}
            onChange={(e) => setFirstPasswordState(e.target.value)}
          />
          <EyeBtn
            className={`${firstShowPassword ? "show-password" : ""}`}
            onClick={() => setFirstShowPassword(!firstShowPassword)}
          ></EyeBtn>
        </div>
        <div style={{ position: "relative" }}>
          <Input
            label="Повторите пароль*"
            type={secondShowPassword ? "text" : "password"}
            name="password"
            error={"password" in errorState && errorState.password}
            value={secondPasswordState}
            onChange={(e) => setSecondPasswordState(e.target.value)}
          />
          <EyeBtn
            className={`${secondShowPassword ? "show-password" : ""}`}
            onClick={() => setSecondShowPassword(!secondShowPassword)}
          ></EyeBtn>
        </div>
      </Stack>
      <Stack space="16px">
        <H5Text>Ссылки на соцсети/сайт</H5Text>
        <Input
          label="Личный сайт"
          value={userState?.socials?.website}
          onChange={(e) => handleInputChange(e, "socials", "website")}
          error={errorState.website}
        />
        <Input
          label="ВКонтакте"
          value={userState?.socials?.vk}
          onChange={(e) => handleInputChange(e, "socials", "vk")}
          error={errorState.vk}
        />
        <Input
          label="Телеграм"
          value={userState?.socials?.telegram}
          onChange={(e) => handleInputChange(e, "socials", "telegram")}
          error={errorState.telegram}
        />
        <Input
          label="Youtube"
          value={userState?.socials?.youtube}
          onChange={(e) => handleInputChange(e, "socials", "youtube")}
          error={errorState.youtube}
        />
      </Stack>
      <Cluster justify="space-between" style={{ marginTop: "32px" }}>
        <Button
          size="big"
          variant="secondary"
          onClick={() => setActive(1)}
          text="Назад"
        />
        <Button
          size="big"
          disabled={
            !userState.password ||
            !secondPasswordState.length ||
            !userState.password.length ||
            secondPasswordState !== secondPass ||
            !userState.email.length ||
            errorState.telegram ||
            errorState.vk ||
            errorState.youtube ||
            errorState.website
          }
          variant="primary"
          onClick={handleNextClick}
          text="Продолжить"
        />
      </Cluster>
    </Stack>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     login: state.login,
//   };
// };

// const mapDispatchToProps = {
//   postLogin,
// };

export default connect(null, null)(SecondRegStep);
