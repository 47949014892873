export const ChatsReducer = (login = [], action) => {
  switch (action.type) {
    case "GET_CHATROOMS":
      return action.chat.data;
    default:
      return login;
  }
};
export const ChatReducer = (login = [], action) => {
  switch (action.type) {
    case "GET_CHATROOM":
      return action.chat.data;
    default:
      return login;
  }
};
