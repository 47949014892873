const compare = (a, b) => {
  if (a.service < b.serivce) {
    return -1;
  }
  if (a.service > b.service) {
    return 1;
  }
  return 0;
};

const sortCategories = (array) => {
  let firstLevel = array
    .filter((item) => item.parent_id === 1 || item.parent_id === 2)
    .sort(compare);
  firstLevel = firstLevel.map((elem) => {
    return { ...elem, orderIndex: 1 };
  });
  const categoriesFullList = firstLevel.map((elem) => {
    return { ...elem, isDisabled: true };
  });
  array = array.filter(
    (item) =>
      item.parent_id !== null && item.parent_id !== 1 && item.parent_id != 2
  );
  array.map((item, index) => {
    const newIndex = firstLevel.findIndex((elem) => elem.id === item.parent_id);
    const fullListIndex = categoriesFullList.findIndex(
      (elem) => elem.id === item.parent_id
    );
    if (newIndex >= 0) {
      if (firstLevel[newIndex].orderIndex === 2) {
        if (firstLevel[newIndex].childs) {
          firstLevel[newIndex].childs.push(item);
        } else {
          firstLevel[newIndex] = { ...firstLevel[newIndex], childs: [item] };
        }
      } else {
        firstLevel.splice(newIndex + 1, 0, {
          ...item,
          orderIndex: firstLevel[newIndex]["orderIndex"] + 1,
        });
      }
    }
    if (fullListIndex >= 0) {
      if (categoriesFullList[fullListIndex].orderIndex === 1) {
        categoriesFullList.splice(fullListIndex + 1, 0, {
          ...item,
          orderIndex: categoriesFullList[fullListIndex]["orderIndex"] + 1,
          isDisabled: true,
        });
      } else if (categoriesFullList[fullListIndex].orderIndex === 2) {
        categoriesFullList.splice(fullListIndex + 1, 0, {
          ...item,
          orderIndex: categoriesFullList[fullListIndex]["orderIndex"] + 1,
        });
      }
    }
  });
  array = firstLevel;
  array.map((item, index) => {
    if (item.orderIndex === 2) {
      item.childs?.sort((a, b) => a.title.localeCompare(b.title));
      const newIndex = firstLevel.findIndex(
        (elem) => elem.id === item.parent_id
      );
      if (newIndex >= 0 && firstLevel[newIndex].orderIndex === 1) {
        if (firstLevel[newIndex].childs) {
          firstLevel[newIndex].childs.push(item);
        } else {
          firstLevel[newIndex] = { ...firstLevel[newIndex], childs: [item] };
        }
      } else {
        firstLevel.splice(newIndex + 1, 0, {
          ...item,
          orderIndex: firstLevel[newIndex]["orderIndex"] + 1,
        });
      }
    }
  });
  firstLevel = firstLevel.filter((item) => item.orderIndex !== 2);
  const categories = firstLevel
    .filter((elem) => elem.parent_id === 1)
    .sort((a, b) => a.title.localeCompare(b.title));
  const service = firstLevel
    .filter((elem) => elem.parent_id === 2)
    .sort((a, b) => a.title.localeCompare(b.title));

  categories.forEach((element) => {
    element?.childs?.sort((a, b) => a.title.localeCompare(b.title));
  });
  service.forEach((element) => {
    element?.childs?.sort((a, b) => a.title.localeCompare(b.title));
  });
  return {
    array: [categories, service],
    object: {
      categories: categories,
      service: service,
      categoriesFullList: categoriesFullList,
    },
  };
};

const ExtraCategoriesReducer = (categor = [], action) => {
  switch (action.type) {
    case "GET_EXTRA_CATEGORIES":
      const data = action.categor.data;
      const parentData = [...data[0], ...data[1]].filter(
        (elem) => elem.parent_id === 1 || elem.parent_id === 2
      );
      return {
        array: [data[0], data[1]],
        parent: parentData,
      };
    default:
      return categor;
  }
};

const BloggerCategoriesReducer = (categor = [], action) => {
  switch (action.type) {
    case "GET_BLOGGER_CATEGORIES":
      const data = action.categor.data.items;
      const value = data.sort((a, b) => a.title.localeCompare(b.title));
      return value;
    default:
      return categor;
  }
};

const CategoryAttrReducer = (categor = [], action) => {
  switch (action.type) {
    case "GET_CATEGORY_ATTR":
      return action.categor.data;
    default:
      return categor;
  }
};

export {
  ExtraCategoriesReducer,
  BloggerCategoriesReducer,
  CategoryAttrReducer,
};
