import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { postLogout } from "../../../Actions/AuthorizationActions";
import { AsideToggleContext } from "../../PersonalArea/context/AsideToggleContext";
import "./styles/Sidebar.scss";
import { SimpleBadge } from "../../../ui-kit/badge/SimpleBadge";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import { getReviewAdTotal } from "../../../Actions/AdvertismentActions";
import { getUnreadReportsCount } from "../../../Actions/ReportsActions";
import UniversalLogoutModal from "../../universal/UniversalLogoutModal.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext";

const Sidebar = ({
  postLogout,
  advertismentTotal,
  getReviewAdTotal,
  getUnreadReportsCount,
  undreadReportsTotal,
  authUser,
}) => {
  const location = useLocation();
  const [currentPageState, setCurrentPageState] = useState("/");
  const { toggle } = React.useContext(AsideToggleContext);

  useEffect(() => {
    setCurrentPageState(location.pathname);
    getReviewAdTotal();
  }, [location]);

  useEffect(() => {
    if (!authUser || !authUser.id) return;
    getUnreadReportsCount(authUser.id);
  });

  const [adCount, setAdCount] = useState({ 1: 0 });
  const [ticketsCount, setTicketsCount] = useState({ 1: 0 });

  useEffect(() => {
    setTicketsCount(undreadReportsTotal);
  }, [undreadReportsTotal]);

  useEffect(() => {
    setAdCount(advertismentTotal);
  }, [advertismentTotal]);

  const { handleModal } = useContext(ModalContext);
  const handleLogout = () => {
    handleModal(
      <UniversalLogoutModal/>
    );
  };
  return (
    <aside
      className={`aside_menu animte-menu ${toggle ? "closed" : ""}`}
      id="main-menu"
    >
      <Link className="inner-logo" id="inner-logo" to="/">
        <div id="inner-logo-colors" className="animte-menu">
          <div className="inner-logo-color animte-menu">
            <img src="images/icons/main/logo_color.svg" alt="color" />
          </div>
          <div className="inner-logo-color animte-menu">
            <img src="images/icons/main/logo_color.svg" alt="color" />
          </div>
          <div className="inner-logo-color animte-menu">
            <img src="images/icons/main/logo_color.svg" alt="color" />
          </div>
        </div>
        <img
          src="images/icons/main/logo.svg"
          alt="logotype"
          id="logo-inner-image"
          className="animte-menu"
        />
      </Link>
      <ul className="inner-menu-list inner-menu">
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/" ? "active" : ""
          }`}
        >
          <Link to="/">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4855 0.142507C11.8022 -0.0475024 12.1978 -0.0475023 12.5145 0.142507L22.5145 6.14251C22.8157 6.32323 23 6.64874 23 7L23 22C23 22.5523 22.5523 23 22 23H16H8H6.8H2C1.44772 23 1 22.5523 1 22L1 7C1 6.64874 1.1843 6.32323 1.4855 6.14251L11.4855 0.142507ZM12 2.16619L3 7.56619V21H6.8H7L7 19L7 16L7 12C7 11.4477 7.44772 11 8 11L16 11C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12V16V19V21H21L21 7.56619L12 2.16619ZM15 21V19L15 16V13L9 13V16V19V21H15Z"
                fill="#212121"
              />
            </svg>
            <span className="animte-menu">Главная</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/tips" ? "active" : ""
          }`}
        >
          <Link to="/tips">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4C3.44772 4 3 4.44772 3 5V16C3 16.5523 3.44772 17 4 17H9C9.35126 17 9.67677 17.1843 9.85749 17.4855L12 21.0563L14.1425 17.4855C14.3232 17.1843 14.6487 17 15 17H20C20.5523 17 21 16.5523 21 16V5C21 4.44772 20.5523 4 20 4H4ZM1 5C1 3.34315 2.34315 2 4 2H20C21.6569 2 23 3.34315 23 5V16C23 17.6569 21.6569 19 20 19H15.5662L12.8575 23.5145C12.6768 23.8157 12.3513 24 12 24C11.6487 24 11.3232 23.8157 11.1425 23.5145L8.43381 19H4C2.34315 19 1 17.6569 1 16V5Z"
                fill="#212121"
              />
            </svg>
            <span className="animte-menu">Типсы</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/videos" ||
            currentPageState === "/shorts" ||
            currentPageState === "/edit-video" ||
            currentPageState === "/new-shorts" ||
            currentPageState === "/new-video"
              ? "active"
              : ""
          }`}
        >
          <Link to="/videos">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1442_3436)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM16.0347 12.8619L10.507 16.1135C9.84039 16.5057 9 16.025 9 15.2516V8.74842C9 7.975 9.84039 7.49435 10.507 7.88648L16.0347 11.1381C16.692 11.5247 16.692 12.4753 16.0347 12.8619Z"
                  fill="#212121"
                />
              </g>
              <defs>
                <clipPath id="clip0_1442_3436">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="animte-menu">Видео</span>
          </Link>
        </li>

        <li
          className={`inner-menu-list__item ${
            currentPageState === "/live" ? "active" : ""
          }`}
        >
          <Link to="/live">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 11.9996C7 10.3104 7.83767 8.81687 9.12028 7.91169L7.74712 6.43925C6.077 7.71857 5 9.73333 5 11.9996C5 14.266 6.077 16.2807 7.74712 17.56L9.12028 16.0876C7.83767 15.1824 7 13.6889 7 11.9996ZM17 11.9996C17 10.3105 16.1623 8.8169 14.8798 7.91172L16.2529 6.43928C17.923 7.7186 19 9.73335 19 11.9996C19 14.2659 17.923 16.2807 16.2529 17.56L14.8798 16.0876C16.1623 15.1824 17 13.6888 17 11.9996ZM22 11.9996C22 8.86743 20.5599 6.07156 18.3058 4.23799L19.6725 2.77249C22.3168 4.97368 24 8.2901 24 11.9996C24 15.7092 22.3168 19.0256 19.6725 21.2268L18.3058 19.7613C20.5599 17.9277 22 15.1319 22 11.9996ZM0 11.9996C0 8.29008 1.68322 4.97365 4.32756 2.77246L5.69424 4.23796C3.44007 6.07153 2 8.86742 2 11.9996C2 15.1319 3.44007 17.9278 5.69424 19.7613L4.32756 21.2268C1.68322 19.0256 0 15.7092 0 11.9996ZM12 13.9998C13.1046 13.9998 14 13.1043 14 11.9998C14 10.8952 13.1046 9.99976 12 9.99976C10.8954 9.99976 10 10.8952 10 11.9998C10 13.1043 10.8954 13.9998 12 13.9998Z"
                fill="#212121"
              />
            </svg>

            <span className="animte-menu">Live</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/playlist" ? "active" : ""
          }`}
        >
          <Link to="/playlist">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 7L22 7V5L2 5V7ZM2 13H13V11H2V13ZM13 19H2V17H13V19ZM17.5408 19.0095L21.6915 16.3412C22.3038 15.9475 22.3038 15.0525 21.6915 14.6588L17.5408 11.9905C16.8752 11.5627 16 12.0405 16 12.8317L16 18.1683C16 18.9595 16.8752 19.4373 17.5408 19.0095Z"
                fill="#212121"
              />
            </svg>

            <span className="animte-menu">Плейлисты</span>
          </Link>
        </li>

        <li
          className={`inner-menu-list__item ${
            currentPageState === "/advertisement" ? "active" : ""
          }`}
        >
          <Link to="/advertisement">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM11 8H11.5C12.6751 8 13.5931 8.17962 14.1844 8.51879C14.6967 8.81264 15 9.23668 15 10C15 10.7633 14.6967 11.1874 14.1844 11.4812C13.5931 11.8204 12.6751 12 11.5 12H11V8ZM11 14H11.5C12.8102 14 14.1421 13.8111 15.1796 13.2161C16.296 12.5757 17 11.4997 17 10C17 8.50029 16.296 7.42433 15.1796 6.78394C14.1421 6.18887 12.8102 6 11.5 6H10C9.44772 6 9 6.44772 9 7V12H8H7V14H8H9V15H8H7V17H8H9V18V19H11V18V17H13H14V15H13H11V14Z"
                fill="#212121"
              />
            </svg>

            <span className="animte-menu">
              <Cluster space="8px">
                Реклама{" "}
                {adCount[Number("1")] > 0 ? (
                  <SimpleBadge
                    value={`+${adCount[Number("1")]}`}
                    backgroundColor="#7027C2"
                  />
                ) : null}
              </Cluster>
            </span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/profile" ? "active" : ""
          }`}
        >
          <Link to="/profile">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8ZM12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2ZM8 16C4.68629 16 2 18.6863 2 22H4C4 19.7909 5.79086 18 8 18H16C18.2091 18 20 19.7909 20 22H22C22 18.6863 19.3137 16 16 16H8Z"
                fill="#212121"
              ></path>
            </svg>
            <span className="animte-menu">Профиль</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/politics" ? "active" : ""
          }`}
        >
          <Link to="/politics">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#a)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6a1 1 0 0 1 1-1h9a1 1 0 1 0 0-2H5a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-6a1 1 0 1 0-2 0v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6Zm7.707 9.707 10-10a1 1 0 0 0-1.414-1.414L11 13.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0Z"
                  fill="#212121"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
              </defs>
            </svg>
            <span className="animte-menu">Политика</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/statistic" ? "active" : ""
          }`}
        >
          <Link to="/statistic">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
                fill="#212121"
              />
            </svg>
            <span className="animte-menu">Статистика</span>
          </Link>
        </li>
        <li
          className={`inner-menu-list__item ${
            currentPageState === "/reports" ||
            currentPageState === "/report-detail"
              ? "active"
              : ""
          }`}
        >
          <Link to="/reports">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 10H4C3.44728 10 3 10.4473 3 11L3 14C3 14.5527 3.44728 15 4 15H5V10ZM12 1C7.82629 1 4.2929 4.03171 4.01729 8H4C2.34272 8 1 9.34272 1 11L1 14C1 15.6573 2.34272 17 4 17H5C6.10428 17 7 16.1043 7 15V10C7 9.26211 6.60006 8.61735 6.00521 8.27073C6.13567 5.40167 8.70252 3 12 3C15.2975 3 17.8643 5.40167 17.9948 8.27073C17.3999 8.61735 17 9.26211 17 10V15C17 15.753 17.4164 16.4089 18.0315 16.7501C18.0109 16.83 18 16.9137 18 17V18C18 18.5819 17.5511 19 17.0606 19H15.4707C14.9667 18.3756 14.1637 18 13.3333 18H11.6667C10.2961 18 9 19.0232 9 20.5C9 21.9768 10.2961 23 11.6667 23H13.3333C14.5432 23 15.6949 22.2027 15.9485 21H17.0606C18.7132 21 20 19.6281 20 18V17C21.6573 17 23 15.6573 23 14V11C23 9.34272 21.6573 8 20 8H19.9827C19.7071 4.03171 16.1737 1 12 1ZM19 10V15H20C20.5527 15 21 14.5527 21 14V11C21 10.4473 20.5527 10 20 10H19ZM11.6667 20C11.1972 20 11 20.3208 11 20.5C11 20.6792 11.1972 21 11.6667 21H13.3333C13.8028 21 14 20.6792 14 20.5C14 20.3208 13.8028 20 13.3333 20H11.6667Z"
                fill="#212121"
              />
            </svg>

            <span className="animte-menu">
              <Cluster space="8px">
                Жалобы{" "}
                {ticketsCount > 0 ? (
                  <SimpleBadge
                    value={`+${ticketsCount}`}
                    backgroundColor="#7027C2"
                  />
                ) : null}
              </Cluster>
            </span>
          </Link>
        </li>
      </ul>

      <button className="button_logout" onClick={() => handleLogout()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 1V11H13V1H11ZM3 12.0476C3 8.44058 5.14515 5.32707 8.24421 3.90936L7.41221 2.09064C3.63168 3.82009 1 7.62543 1 12.0476C1 18.1009 5.92937 23 12 23C18.0706 23 23 18.1009 23 12.0476C23 7.62543 20.3683 3.82009 16.5878 2.09064L15.7558 3.90936C18.8549 5.32707 21 8.44058 21 12.0476C21 16.9874 16.9751 21 12 21C7.02493 21 3 16.9874 3 12.0476Z"
            fill="#212121"
          />
        </svg>
        <span className="animte-menu">Выйти</span>
      </button>
    </aside>
  );
};
const mapStateToProps = (state) => {
  return {
    advertismentTotal: state.advertismentTotalReducer,
    undreadReportsTotal: state.undreadReportsTotal,
    authUser: state.authUser,
  };
};

const mapDispatchToProps = {
  postLogout,
  getReviewAdTotal,
  getUnreadReportsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
