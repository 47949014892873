import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import { Button } from "../../../ui-kit/button/Button";
import { Textarea } from "../../../ui-kit/textarea/Textarea";
import { Stack } from "../../../ui-kit/layouts/Stack";
import styled from "styled-components";
import { DText14 } from "../../../ui-kit/typography/Typography";
import UploadImages from "./UploadImages";
import axios from "axios";
import { Cluster } from "../../../ui-kit/layouts/Cluster";

const StyledStack = styled(Stack)`
  width: 100%;
`;

const StyledInput = styled.div`
  background: #f4f4f8;
  border-radius: 8px;
  position: relative;
  border: 1px solid #f4f4f8;
  border-radius: 8px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  min-height: 104px;
  color: #212121;
  width: 100%;
  padding: 16px 20px;
  outline: none;
  resize: none;
  input {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const CompletedContainer = styled(Cluster)`
  background: #7027c2;
  box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
  border-radius: 16px;
  padding: 16px 32px;
  height: 72px;
  width: 288px;
  border-radius: 8px;
`;

const StyledTextarea = styled.textarea`
  background: #f4f4f8;
  border-radius: 8px;

  border: 1px solid #f4f4f8;
  border-radius: 8px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  min-height: 104px;
  color: #212121;
  width: 100%;
  padding: 16px 20px;
  outline: none;
  resize: none;
  height: 56px;
  overflow-y: hidden;
  &.error {
    border: 1px solid #ed0a34 !important;
    &:hover,
    &:focus {
      border: 1px solid #ed0a34 !important;
    }
  }
  &::placeholder {
    color: #94a0b1;
  }
  &:focus {
    border: 1px solid #7027c2;
  }
`;

const FeedbackModal = ({ authUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleModal, modal } = useContext(ModalContext);

  const [messageState, setMessageState] = useState("");
  const [uploadState, setUploadState] = useState(null);
  const [ready, setReady] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSelectClick = async () => {
    setLoading(true);
    if (!loading) {
      await axios
        .post("https://atips.io/atipscreators/", {
          title: authUser.name,
          email: authUser.email,
          id: authUser.serial_id,
          text: messageState,
          image: uploadState || "",
        })
        .then((res) => {
          setLoading(false);
          setReady(1);
          setTimeout(() => {
            setReady(2);
          }, 3000);
        });
    }
  };

  useEffect(() => {
    const tx = document.getElementsByTagName("textarea");
    if (tx.length > 0)
      for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute(
          "style",
          "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
        );
        tx[i].addEventListener("keydown", OnInput, false);
      }

    function OnInput() {
      this.style.height = "104px";
      this.style.height = this.scrollHeight + "px";
    }
    return () => {
      for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;");
        tx[i].removeEventListener("", OnInput, false);
      }
    };
  }, [messageState]);

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessageState(value);
  };
  useEffect(() => {
    if (ready === 2) {
      handleModal();
    }
  }, [ready]);

  return ready === 1 ? (
    <CompletedContainer align="center" justify="space-between">
      <DText14 style={{ color: "#FFFFFF" }}>Сообщение отправлено</DText14>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4142 7.00015L20.7071 7.70726L10.7071 17.7073C10.5196 17.8948 10.2652 18.0002 10 18.0002C9.7348 18.0002 9.48045 17.8948 9.29291 17.7073L3.29292 11.7073L2.58582 11.0001L4.00003 9.58594L4.70714 10.293L10 15.586L19.2929 6.29304L20 5.58594L21.4142 7.00015Z"
          fill="white"
        />
      </svg>
    </CompletedContainer>
  ) : (
    <>
      <div className="add-category-wrapper" style={{ width: "504px" }}>
        <div className="modal-field-title">Написать нам</div>
        <div className="modal-field-close" onClick={() => handleModal()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
              fill="#212121"
            ></path>
          </svg>
        </div>
        <Stack space="24px">
          <StyledStack space="5px">
            <DText14 style={{ color: "#94A0B1" }}>Сообщение</DText14>
            <StyledTextarea
              value={messageState}
              onChange={handleMessageChange}
              placeholder="Напишите нам, если обнаружили ошибку или если у вас есть идея как нам улучшить сервис."
            />
          </StyledStack>
          <StyledStack space="5px">
            <DText14 style={{ color: "#94A0B1" }}>
              Прикрепить файл (не обязательно)
            </DText14>
            <UploadImages
              uploadState={uploadState}
              setUploadState={setUploadState}
            />
          </StyledStack>
        </Stack>
        <div className="modal-buttons delete">
          <Button
            onClick={() => handleModal()}
            text="Отменить"
            variant="secondary"
          />
          <Button
            className={`${loading ? "preloader" : ""}`}
            variant="primary"
            disabled={!messageState || !messageState?.length}
            onClick={() => handleSelectClick()}
            text="Отправить"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    loading: state.loadingStatus.loading,
  };
};

// const mapDispatchToProps = {
// 	deleteUser,
// };

export default connect(mapStateToProps, null)(FeedbackModal);
