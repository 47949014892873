import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App/App.jsx';
import { appStore, history } from './Store/Store';
import { setupInterceptors } from "./services/setupInterceptors.js";
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ModalProvider } from './components/modal/ModalContext/ModalContext';
import { BrowserRouter as Router, } from 'react-router-dom';


export const client = new ApolloClient({
  uri: 'https://devstat.testatips.ru/graphql',
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={appStore}>
      <ConnectedRouter history={history}>
        <Router>
          <ModalProvider>
            <App />
          </ModalProvider>
        </Router>

      </ConnectedRouter>
    </Provider>

  </ApolloProvider >,
  document.getElementById('root')
);

setupInterceptors();
