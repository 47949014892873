import React from "react";
import { connect } from "react-redux";
import { LEGAL_POLICY } from "./policies";

const Legal = () => {
  return (
    <div className="policy-page">
      <div className="policy-page__header">
        <img src="images/icons/main/logo.svg" alt="" id="logo" />
      </div>

      <div className="policy-page__content">
        <div className="policy-page__title">
          Пользовательское соглашение — оферта
        </div>
        <div
          style={{
            fontSize: "14px",
            lineHeight: "22px",
          }}
          dangerouslySetInnerHTML={{
            __html: LEGAL_POLICY,
          }}
        />
      </div>
    </div>
  );
};

export default connect(null, null)(Legal);
