import React, { lazy, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../universal/Sidebar/Sidebar.jsx";
import { AsideToggleContext } from "./context/AsideToggleContext";
import DropDownSaveInfo from "../universal/DropDownSaveInfo/DropDownSaveInfo.jsx";
import "./styles/PersonalArea.scss";
import PersonalAreaNavbar from "../universal/Navbar/PersonalAreaNavbar.jsx";
import { connect } from "react-redux";
import { getAuthUser } from "../../Actions/AuthorizationActions.js";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import preloaderJson from "./preloader.json";
import { Suspense } from "react";
import TokenService from "../../services/token.service.js";
import { ModalContext } from "../modal/ModalContext/ModalContext";
import { UploadStatusInfo } from "../universal/Navbar/UploadStatus.jsx";
import styled from "styled-components";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { DTBold14 } from "../../ui-kit/typography/Typography.jsx";
import FeedbackModal from "../universal/Navbar/FeedbackModal.jsx";
import NotFoundPage from "../Index/NotFoundPage.jsx";
import AccountBannedModal from "./AccountBannedModal.jsx";

const BrightboxesPage = lazy(() =>
  import("./BrightboxesPage/BrightboxesPage.jsx")
);
const ReportsPage = lazy(() => import("./ReportsPage/ReportsPage.jsx"));
const ReportDetail = lazy(() =>
  import("./ReportsPage/report-detail/ReportDetail.jsx")
);
const StatisticPage = lazy(() => import("./StatisticPage/StatisticPage.jsx"));
const LivePage = lazy(() => import("./LivePage/LivePage.jsx"));
const PlaylistsPage = lazy(() => import("./PlaylistsPage/PlaylistsPage.jsx"));
const ChatPage = lazy(() => import("./ChatPage/ChatPage.jsx"));
const EditVideos = lazy(() => import("./VideosPage/EditVideos/EditVideos.jsx"));
const PoliticsPage = lazy(() => import("./PoliticsPage/PoliticsPage.jsx"));
const VideosPage = lazy(() => import("./VideosPage/VideosPage.jsx"));
const ProfilePage = lazy(() => import("./ProfilePage/ProfilePage.jsx"));
const HomePage = lazy(() => import("./HomePage/HomePage.jsx"));
const AdvertismentPage = lazy(() =>
  import("./AdvertismentPage/AdvertismentPage.jsx")
);

const TestWrapper = styled.div`
  background: #7027c2;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 20px 0 32px;
  width: 100%;
  align-items: center;
  height: 48px;
  margin-top: -48px;
  z-index: 2;
  &.open {
    margin-top: 0px;
    transform: translateY(0px);
  }
  /* transition: all 0.5s; */
`;
const ExitButton = styled.div`
  cursor: pointer;
`;

const PersonalArea = ({
  mainLoader,
  statisticLoadingStatus,
  getAuthUser,
  authUser,
}) => {
  const { toggle } = React.useContext(AsideToggleContext);
  const { flagState, handleModal } = useContext(ModalContext);
  useEffect(() => {
    const userId = TokenService.getUser();
    userId && getAuthUser(userId);
  }, []);
  const [testInfoStatus, setTestInfoStatus] = useState(() => true);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (authUser && authUser.application_status === "rejected") {
      handleModal(<AccountBannedModal />);
    }
  }, [authUser]);

  return (
    <>
      {/* <div id="block-on-mobile">
        <img src="images/auth_logo.svg" alt="" id="mobile-logo" />
      </div> */}
      <DropDownSaveInfo />
      <Sidebar />
      <div
        className={`personal-area-section animte-menu ${
          toggle ? "closed" : ""
        } ${flagState ? "modal" : ""}`}
        style={{ overflowY: "auto" }}
      >
        <TestWrapper className={testInfoStatus ? "open" : ""}>
          <Cluster space="6px">
            <DTBold14 style={{ color: "white" }}>
              Atips работает в тестовом режиме.
            </DTBold14>
            <DTBold14
              style={{
                color: "white",
                borderBottom: "1px solid #ffffff",
                cursor: "pointer",
              }}
              onClick={() => handleModal(<FeedbackModal />)}
            >
              Напишите нам
            </DTBold14>
            <DTBold14 style={{ color: "white" }}>
              и помогите стать еще лучше!
            </DTBold14>
          </Cluster>
          <ExitButton onClick={() => setTestInfoStatus(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.70714 5.29304L6.00003 4.58594L4.58582 6.00015L5.29292 6.70726L10.5858 12.0002L5.29292 17.293L4.58582 18.0002L6.00003 19.4144L6.70714 18.7073L12 13.4144L17.2929 18.7073L18 19.4144L19.4142 18.0002L18.7071 17.293L13.4142 12.0002L18.7071 6.70726L19.4142 6.00015L18 4.58594L17.2929 5.29304L12 10.5859L6.70714 5.29304Z"
                  fill="white"
                />
              </g>
            </svg>
          </ExitButton>
        </TestWrapper>
        <UploadStatusInfo
          isOpen={isOpen}
          setOpen={setOpen}
          other={testInfoStatus}
        />
        <PersonalAreaNavbar />
        <Suspense
          fallback={
            <div className={`main-loader-wrapper active`}>
              <Lottie
                loop
                animationData={preloaderJson}
                play
                style={{ width: 50, height: 50 }}
              />
            </div>
          }
        >
          <Routes>
            <Route
              exact
              path="/"
              element={<HomePage title="Atips Creator" />}
            />
            <Route
              exact
              path="/profile"
              element={<ProfilePage title="Профиль" />}
            />
            <Route
              exact
              path="/politics"
              element={<PoliticsPage title="Политика" />}
            />
            <Route
              exact
              path="/advertisement"
              element={<AdvertismentPage title="Реклама" />}
            />
            <Route
              exact
              path="/playlist"
              element={<PlaylistsPage title="Плейлисты" />}
            />
            <Route
              exact
              path="/statistic"
              element={<StatisticPage title="Статистика" />}
            />

            <Route
              exact
              path="/chat"
              element={
                <ChatPage
                  title="Чат"
                  promo={Boolean(isOpen || testInfoStatus)}
                />
              }
            />
            <Route
              exact
              path="/tips"
              element={<BrightboxesPage title="Типсы" />}
            />
            <Route exact path="/live" element={<LivePage title="Чат" />} />

            <Route
              exact
              path="/videos"
              element={<VideosPage title="Видео" />}
            />
            <Route
              exact
              path="/shorts"
              element={<VideosPage title="Видео" />}
            />
            <Route
              exact
              path="/live-list"
              element={<VideosPage title="Видео" />}
            />
            <Route
              exact
              path="/edit-video"
              element={<EditVideos title="Видео" />}
            />
            <Route
              exact
              path="/edit-shorts"
              element={<EditVideos title="Видео" />}
            />
            <Route
              exact
              path="/new-video"
              element={<EditVideos title="Видео" />}
            />
            <Route
              exact
              path="/new-shorts"
              element={<EditVideos title="Видео" />}
            />
            <Route
              exact
              path="/reports"
              element={<ReportsPage title="Жалобы" />}
            />
            <Route
              exact
              path="/report-detail"
              element={<ReportDetail title="Тикет жалобы" />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <div
          className={`main-loader-wrapper ${
            mainLoader > 0 || statisticLoadingStatus ? "active" : "passive"
          }`}
        >
          <Lottie
            loop
            animationData={preloaderJson}
            play
            style={{ width: 50, height: 50 }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    mainLoader: state.mainLoader,
    statisticLoadingStatus: state.statisticLoadingStatus,
  };
};

const mapDispatchToProps = {
  getAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalArea);
