import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { postLogin } from "../../Actions/AuthorizationActions.js";
import "./styles/Authorization.scss";
import {
  DTBold13,
  DText13,
  H4Text,
} from "../../ui-kit/typography/Typography.jsx";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../ui-kit/layouts/Stack.jsx";
import { initialUser } from "./initial.js";
import RegistrationTabs from "./RegistrationTabs.jsx";
import FirstRegStep from "./registration-steps/FirstRegStep.jsx";
import SecondRegStep from "./registration-steps/SecondRegStep.jsx";
import ThirdRegStep from "./registration-steps/ThirdRegStep.jsx";

const Registration = () => {
  let navigate = useNavigate();
  const [userState, setUserState] = useState(initialUser);
  const [activeTabState, setActiveTabState] = useState(1);

  return (
    <section id="auth">
      <div id="auth-form" style={{ width: "600px" }}>
        <Stack>
          <img src="images/icons/main/logo.svg" alt="" id="logo" />
          <Cluster justify="center">
            <H4Text className="title">Регистрация</H4Text>
          </Cluster>
        </Stack>
        <RegistrationTabs
          active={activeTabState}
          userState={userState}
          setUserState={setUserState}
        />
        {activeTabState === 1 ? (
          <FirstRegStep
            setActive={setActiveTabState}
            userState={userState}
            setUserState={setUserState}
          />
        ) : activeTabState === 2 ? (
          <SecondRegStep
            setActive={setActiveTabState}
            userState={userState}
            setUserState={setUserState}
          />
        ) : activeTabState === 3 ? (
          <ThirdRegStep
            setActive={setActiveTabState}
            userState={userState}
            setUserState={setUserState}
          />
        ) : (
          <FirstRegStep
            setActive={setActiveTabState}
            userState={userState}
            setUserState={setUserState}
          />
        )}

        <Cluster space="3px" justify="center" style={{ marginTop: "40px" }}>
          <DText13 style={{ color: "#94A0B1" }}>
            Уже есть профиль в Atips? Тогда
          </DText13>
          <DTBold13
            style={{ marginTop: "0.12em" }}
            className="register-btn"
            onClick={() => navigate("/sign_in")}
          >
            Войдите
          </DTBold13>
        </Cluster>
      </div>
    </section>
  );
};

export default connect(null, null)(Registration);
