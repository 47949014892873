import React from "react";
import { connect } from "react-redux";
import "./policies.scss";
import { PRIVACY_POLICY } from "./policies";

const Policy = () => {
  return (
    <>
      <div className="policy-page">
        <div className="policy-page__header">
          <img src="images/icons/main/logo.svg" alt="" id="logo" />
        </div>

        <div className="policy-page__content">
          <div className="policy-page__title">Политика конфиденциальности</div>
          <div
            style={{
              fontSize: "14px",
              lineHeight: "22px",
            }}
            dangerouslySetInnerHTML={{
              __html: PRIVACY_POLICY,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, null)(Policy);
