import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import {
  LoadingReducer,
  MainLoadingReducer,
  SaveReducer,
} from "./LoadingReducer.js";
import {
  AuthorizationReducer,
  AuthUserReducer,
} from "./AuthorizationReducer.js";
import { PoliticsLocationReducer, PoliticsReducer } from "./PoliticsReducer.js";
import {
  AdvertismentReducer,
  AdvertismentTotalReducer,
} from "./AdvertismentReducer.js";
import { DashboardSellersReducer, SellersReducer } from "./SellersReducer.js";
import {
  BloggerCategoriesReducer,
  CategoryAttrReducer,
  ExtraCategoriesReducer,
} from "./CategoriesReducer.js";
import {
  CurrentVideoViewsDepthGraphReducer,
  CurrentVideoViewsGraphReducer,
  VideoProfitGraphReducer,
  VideosCountByFormats,
  VideosViewsGraphReducer,
} from "./MainStatisticReducer.js";
import { PlaylistReducer } from "./PlaylistReducer.js";
import { VideoCurrentReducer, VideosReducer } from "./VideosReducer.js";
import { GenresReducer, VideoCaregoryReducer } from "./GenresReducer.js";
import { CountriesReducer } from "./LocationReducer.js";
import { ProductReducer } from "./ProductReducer.js";
import { ChatReducer, ChatsReducer } from "./ChatsReducer.js";
import { NotificationReducer } from "./NotificationReducer.js";
import { BrightboxesReducer } from "./BrightBoxesReducer.js";
import {
  VideoViewGadgetGraphReducer,
  VideoViewGadgetStatisticReducer,
} from "./VideoStatisticReducer.js";
import {
  CurrentReportReducer,
  ReportsReducer,
  ReportsTotalReducer,
  UndreadReportsTotalReducer,
} from "./ReportReducer.js";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history), // состояние роутера
    login: AuthorizationReducer, // состояние авторизации

    mainLoader: MainLoadingReducer, // статус главной подгрузки (получкние, обновление) списков данных (список видео, список статистики и т.д....) (счётчик)
    saveMoment: SaveReducer, // Индикатор сохранения (true/false)
    loadingStatus: LoadingReducer, // статус загрузки (true / false)

    authUser: AuthUserReducer, // сущность авторизованного пользователя
    // uploadImage: UploadImageReducer, // состояние сохранённого изображения

    // главная
    videosCountByFormats: VideosCountByFormats,

    // Видео
    videosList: VideosReducer,
    currentVideo: VideoCurrentReducer,
    // политика
    politicsList: PoliticsReducer,
    politicsLocation: PoliticsLocationReducer,

    // реклама
    advertismentReducer: AdvertismentReducer, // список внешних блогеров
    advertismentTotalReducer: AdvertismentTotalReducer, // список внешних блогеров

    // Продавцы
    sellersList: SellersReducer,
    dashboardSellersList: DashboardSellersReducer,

    // Категории
    categoriesList: ExtraCategoriesReducer,
    bloggerCategoriesList: BloggerCategoriesReducer,
    categoryAttr: CategoryAttrReducer,

    // Плейлисты
    playlistsList: PlaylistReducer,

    // location
    countriesList: CountriesReducer, // список стран

    // Прочее
    genresList: GenresReducer,
    videoCaregoryList: VideoCaregoryReducer,
    currentProduct: ProductReducer,

    // чаты
    chatsList: ChatsReducer,
    currentChat: ChatReducer,

    // уведомления
    notificationList: NotificationReducer,

    // типсы
    brightboxesList: BrightboxesReducer, // список типсов

    // report
    reportsList: ReportsReducer,
    currentReport: CurrentReportReducer,
    reportsTotal: ReportsTotalReducer,
    undreadReportsTotal: UndreadReportsTotalReducer, // кол-во непрочитанных репортов

    // graphs
    videoProfitGraph: VideoProfitGraphReducer,
    videoViewsGraph: VideosViewsGraphReducer,
    currentVideoViewsGraph: CurrentVideoViewsGraphReducer,
    CurrentVideoViewsDepthGraph: CurrentVideoViewsDepthGraphReducer,

    videoViewGadgetGraph: VideoViewGadgetGraphReducer,
    videoViewGadgetStatistic: VideoViewGadgetStatisticReducer,
  });

export default createRootReducer;
