export const LEGAL_POLICY = `<p align="left">
Российская
Федерация, г. Москва</p>
<p align="left" style="margin-bottom:0.35cm;  orphans: 0; widows: 0">
Редакция от «___» апреля 2023 года
</p>
<p style="margin-bottom: 0.35cm">Настоящее Пользовательское соглашение является публичной офертой (далее –
«Соглашение») согласно ст.437 Гражданского кодекса Российской Федерации (далее также – «ГК РФ»), представляет
собой официальное предложение <i>общества с ограниченной ответственностью «ЭйТипс» (далее –
  «Администратор»),</i> адресованное физическим лицам или юридическим лицам, осуществляющим
предпринимательскую деятельность (далее также – «Блогер»), заключить Соглашение на использование Сервиса <span
  lang="en-US">ATIPS</span> (ЭЙТИПС) на указанных в нем условиях.</p>

<p style="margin-bottom: 0.35cm">Для акцепта настоящего Соглашения Блогеру достаточно принять его условия и
зарегистрироваться в Личном кабинете.
</p>
<p style="margin-bottom: 0.35cm">Принятие условия Соглашения осуществляется Блогером путем проставления «V» в графе
«Я принимаю условия пользовательского соглашения», что подтверждает полное и безоговорочное принятие условий
Соглашения.
</p>
<p style="margin-bottom: 0.35cm"><i>Администратор информирует Блогера о том, что если он не согласен с какими-либо
  условиями Соглашения, либо ему не понятны какие-то условия Соглашения, ему не следует осуществлять действия
  для акцепта Соглашения и входить в правоотношения с Администратором, возникающие на основании Соглашения,
  т.е. не стоит регистрироваться в Сервисе и пользоваться услугами Администратора.</i></p>

<p style="margin-bottom: 0.35cm">Датой заключения Соглашения между Сторонами является дата акцепта Блогером
Соглашения и Регистрации в Личном кабинете.</p>
<h1 class="western" align="left"><b><i>
    Определения и толкования
  </i></b></h1>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Администратор
</b>общество с ограниченной ответственностью «ЭйТипс», ОГРН 1227700295943, <span>109382,
  109382, г. Москва, ул Нижние Поля, д. 31, стр. 1</span>,
фактический адрес: <span>1109382, г. Москва, ул Нижние Поля, д. 31, стр. 1.</span></p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Блогер</b>
– лицо, заключившее с Администратором Соглашение и прошедшее процедуру Регистрации в Сервисе.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Стороны
  – </b>Администратор и Блогер.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Соглашение
  – </b>настоящее пользовательское соглашение, заключаемое между Сторонами, регулирующее условия
предоставления доступа к Сервису, условия размещения Контента, права и обязанности Сторон, отражающее
последствия нарушения обязательств и содержащее иные условия взаимодействия Сторон.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Соглашение
размещено по адресу: <span style="letter-spacing: 0.2pt"><span><a href='https://creator.atips.io/'
      target='_blank'>creator.atips.io </a></span></span>
в соответствующем разделе сайта.</p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Сервис
  (Интернет-Сервис)</b>
– платформа
«<span lang="en-US">ATIPS</span>», представляющая собой совокупность разработанных Администратором программных
средств, посредством которых Блогер получает возможность размещать Контент на Сервисе для демонстрации его
неопределенному кругу лиц.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Сервис
располагается по адресу: <span style="letter-spacing: 0.2pt"><span><a href='https://creator.atips.io/'
      target='_blank'>creator.atips.io</a></span></span></p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Контент</b>
– аудиовизуальные произведения (видеоролики и иные подобного рода произведения), размещаемые Блогером на
Сервисе, правообладателем которых является Блогер, их отдельные фрагменты, элементы и т.д.</p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm;  orphans: 0; widows: 0">
<b>Личный
  кабинет</b> –
раздел Сервиса, доступ к которому появляется у Блогера после принятия Соглашения и Регистрации на Сервисе
</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Личный кабинет предоставляет возможность
размещения Контента на Сервисе. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Регистрация
  - </b>процедура, включающая создание Блогером Учетной записи, через которую осуществляется вход в Личный
кабинет для доступа к возможностям Сервиса.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><a name="_Hlk133429276"></a><b>Учетная
  запись</b> - под Учетной записью (профилем) Блогера в рамках Соглашения понимается профиль Блогера в
Сервисе, который включает в себя сведения, необходимые для идентификации Блогера, и информацию для
авторизации в Сервисе.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><a name="_Hlk133434954"></a>Учетная запись
создается после Регистрации.</p>


<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Реклама
  (Типсы)</b> – рекламные объявления, всплывающие окна (Типсы), которые отображаются в Контенте во время его
просмотра пользователями. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Клик</b> – каждый случай перехода пользователя
из Сервиса на информационный ресурс (сайт, интернет-магазин) рекламодателя, который осуществляется посредством
нажатия на Типс.</p>




<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Отчетный период </b> – 1 (один) календарный
месяц. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Первый Отчетный период равен периоду времени с
момента заключения Соглашения до истечения первого полного календарного месяца. </p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае прекращения действия Соглашения по любым
основаниям последний Отчетный период равен периоду с момента окончания последнего полного календарного месяца до
момента прекращения действия Соглашения.</p>

<li>
<h1 class="western" align="left"><b><i>1. Предмет
      Соглашения</i></b></h1>
<ol>
  <li>
    <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
      1.1. Администратор предоставляет Блогеру доступ к Сервису – выступает в качестве поставщика услуг
      хостинга, а Блогер получает право на размещение Контента на Сервисе для демонстрации его
      пользователям Сервиса, в связи с чем Блогер:
    </p>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          1.1.1. Загружая Контент на Сервис, предоставляет Администратору неисключительную лицензию
          на использование такого Контента с правом сублицензирования третьим лицам.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Неисключительная лицензия предоставляется Блогером Администратору на следующих условиях:
        </p>
        <ul>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              территория использования: страны всего мира;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              способ использования: доведение до всеобщего сведения по сети Интернет посредством
              Сервиса по модели AVOD, а также отдельно от Сервиса в производственных,
              демонстрационных, промо и рекламных целях исключительно в связи с популяризацией
              (анонсированием, рекламированием, продвижением) Контента и Сервиса, в том числе с
              использованием звука и изображения отдельно для указанных целей, без указания
              Блогера и авторов Контента;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              срок неисключительной лицензии: на срок размещения Контента на Сервисе (с момента
              размещения Контента и до момента его удаления Блогером/Администратором)
            </p>
          </li>
        </ul>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Срок размещения Контента определяется Блогером самостоятельно.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
      1.2. Если иное соглашение не достигнуто между Сторонами, на Сервисе не предусмотрены какие-либо
      платные подписки для просмотра пользователями Контента, либо иные условия получения денежных
      средств за размещенный Блогером Контент, в связи с чем Блогер соглашается на распространение
      Контента по модели AVOD и не вправе рассчитывать на какое-либо вознаграждение за размещение Контента
      на Сервисе и воспроизведение его пользователями, за исключением прямо указанного в Соглашении. </p>
    <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Модель «AVOD» подразумевает под собой
      доведение до всеобщего сведения по сети Интернет Контента, включающее в себя возможность размещения
      трейлеров, рекламных роликов и/или иных рекламных материалов (Рекламы (Типсов) до, после или на
      протяжении всего показа Контента; или включающее в себя возможность размещения баннеров, логотипов,
      иконок, текстов, тегов, символов или иной идентифицирующей информации о продуктах, услугах,
      поставщиках продуктов также до, после или на протяжении всего показа Контента с Сервиса на
      устройство в реальном времени (потоковое вещание) с применением технологии, которая не допускает
      сохранение или иное копирование/загрузку Контента в пользовательское устройство по завершению
      передачи (показа), кроме транзитного сохранения, требуемого для передачи в реальном времени,
      например буфер данных. </p>

  </li>

</ol>
</ol>

<ol start="2" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>2. Использование Сервиса</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.1. Доступ к Сервису осуществляется при помощи сети Интернет через веб-браузер с
          использованием персональных компьютеров или ноутбуков, позволяющих по своим техническим
          характеристикам осуществлять использование Сервиса.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Работа Сервиса зависит от
          скорости сети Интернет и других критериев, в т. ч. используемых Блогерами устройств.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор оставляет за
          собой право изменять перечень устройств, доступ с которых возможен к Сервису. </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.2. Доступ к Сервису возможен Блогеру преимущественно на территории Российской Федерации,
          а в случае отсутствия ограничений – из иных мест. Администратор не несет ответственности за
          ограничение доступа к Сервису за пределами Российской Федерации.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><i>
            2.3. Процесс Регистрации на Сервисе:</i>
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.3.1. Регистрация в Сервисе осуществляется путем прохождения специальной процедуры
              и заполнения всех обязательных полей для Регистрации, либо с использованием сервиса
              партнеров, таких как Tinkoff ID, Сбер ID, МТС ID, ВК ID и т.п.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Регистрация в Сервисе посредством сервиса партнера означает согласие Блогера с
              Соглашением.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.3.2. После заполнения регистрационной формы - данные о Регистрации поступают
              Администратору для осуществления их проверки.
            </p>
            <ol>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.2.1. Если все обязательные данные были указаны Блогером корректно и у
                  Администратора не возникло дополнительных вопросов, Администратор
                  подтверждает Учетную запись.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  С момента подтверждения Учетной записи Блогеру становится доступным Личный
                  кабинет со всеми функциональными возможностями.
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.2.2. Если по каким-либо причинам введенные Блогером данные не прошли
                  проверку со стороны Администратора, последний вправе запросить
                  дополнительные данные и информацию у Блогера, и в случае их предоставления
                  и достаточности Администратор подтверждает Регистрацию и создаваемую
                  Учетную запись.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  В случае непредоставления дополнительно запрошенных данных и информации,
                  либо если Администратор усомнится в благонадежности Блогера, создание
                  Учетной записи Блогеру может быть отклонено.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  Администратор информирует Блогера о том, что он оставляет за собой право без
                  объяснения каких-либо причин отказать Блогеру в Регистрации.
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.2.3. Администратор уведомляет Блогера о принятом решение (п.2.3.2.1,
                  2.3.2.2 Соглашения) любым доступным способом.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.3.3. Вход в Личный кабинет осуществляется с использованием логина и созданного
              пароля, либо посредством сервиса партнера, такого как Tinkoff ID, Сбер ID, МТС ID,
              ВК ID и т.п.</p>

            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Сервис партнер определяется Администратором самостоятельно, без какого-либо
              дополнительного согласования с Блогером, и в течение существования Сервиса такой
              партнер может меняться.</p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Администратор оставляет за собой право вовсе отказаться от использования сервиса
              партнера, в связи с чем доступ к Личному кабинету с использованием логина и пароля.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              В случае наличия технических проблем, препятствующих входу Блогеру в Учетную
              запись, утери пароля, Блогер имеет возможность восстановить пароль согласно
              процедуре, действующей в Сервисе, либо путем обращения к Исполнителю.</p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.3.4. В случае необходимости Администратор оставляет за собой право запросить у
              Блогера следующие документы:
            </p>
            <ol>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.4.1. Копию паспорта;
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.4.2. Копию устава;
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.4.3. Копию свидетельства о регистрации юридического лица либо
                  физического лица в качестве индивидуального предпринимателя;
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.4.4. Копию свидетельства о присвоении ИНН;
                </p>
              </li>
              <li>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  2.3.4.5. Иные документы.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  Блогер обязуется предоставить сканированные копии запрошенных документов
                  Администратору в указанном им порядке.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  Предоставляя Администратору вышеуказанные документы, Блогер подтверждает и
                  гарантирует их достоверность и соответствие оригиналам таких документов, в
                  связи с чем Администратор полагается на их достоверность, как если бы они
                  были представлены на бумажном носителе в оригинале.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  Перечень предоставляемых Блогером документов зависит от статуса Блогера
                  (индивидуальный предприниматель, юридическое лицо) и необходим в целях
                  исполнения Администратором обязательств по Соглашению, в том числе для
                  взаиморасчетов по нему.
                </p>
                <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
                  В случае непредоставления Блогером запрошенных документов, Администратор
                  оставляет за собой право приостановить оказание услуг до момента
                  предоставления всех запрошенных документов.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.4. Доступ к Сервису доступен Блогерам, достигшим 18 лет, а лицам не достигшим – с
          согласия их законных представителей (родителей, опекунов).
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если возраст Блогера не
          соответствует указанному выше критерию, Блогер не должен пользоваться Сервисом без согласия
          родителей или опекуна.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Родители или опекуны Блогера
          младше 18 лет, разрешая Блогеру использовать Сервис, обязаны соблюдать условия Соглашения и
          нести ответственность за действия, которые совершаются Блогером в Сервисе.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">С учетом того, что
          Администратор не может проверить реальный возраст Блогера, Администратор полагается на
          добросовестность Блогера, в связи с чем, в случае регистрации Блогера младше 18 лет в
          Сервисе такой Блогер безоговорочно подтверждает, что такая регистрация была осуществлена с
          согласия родителей или опекуна и Блогер и его законные представители не будут в последствии
          предъявлять какие-либо претензии к Администратору в связи с нарушением их прав
          использованием Сервиса.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор не несет
          ответственности перед Блогером, а также перед его законными представителями за возможность
          получить доступ к Сервису.</p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.5. При помощи Личного кабинета Блогер имеет возможность добавлять, размещать, блокировать
          (удалять), изменять Контент, предполагаемый к размещению на Сервисе, корректировать свои
          персональные данные, а также пользоваться иными доступными возможностями Личного кабинета.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если это применимо, Блогер
          также может осуществлять прямой эфир с использованием Сервиса (стрим).</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Организуя стрим, Блогер обязан
          обеспечить соблюдение условий Соглашения во время такого стрима, в том числе соблюдение
          п.2.7, п.2.8 Соглашения. </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.6. Администратор вправе заблокировать/удалить размещенный Блогером Контент в следующих
          случаях:
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.6.1. Если Контент нарушает условия Соглашения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.6.2. Если Контент может нанести вред Сервису, его пользователям или третьим лицам;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.6.3. Если Контент нарушает авторские права третьих лиц и от таких лиц поступили
              жалобы на Контент;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.6.4. Если Контент нарушает требования действующего законодательства.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.7. Блогер обязан разместить в Контенте знак информационной продукции и (или) текстовое
          предупреждение об ограничении ее распространения среди детей перед началом демонстрации.
          Возрастная категория определяется Блогером самостоятельно.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Всю ответственность за
          некорректную маркировку/отсутствие маркировки в Контенте несет непосредственно Блогер.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.8. На Сервисе категорически запрещается распространение Контента, содержащего:
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.1. информацию, которая направлена на пропаганду войны, разжигание национальной,
              расовой или религиозной ненависти и вражды, а также иную информацию, за
              распространение которой предусмотрена уголовная или административная
              ответственность.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.2. материалы с порнографическими изображениями несовершеннолетних и (или)
              объявлений о привлечении несовершеннолетних в качестве исполнителей для участия в
              зрелищных мероприятиях порнографического характера;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.3. информацию о способах, методах разработки, изготовления и использования
              наркотических средств, психотропных веществ и их прекурсоров, новых потенциально
              опасных психоактивных веществ, местах их приобретения, способах и местах
              культивирования наркосодержащих растений;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.4. информацию о несовершеннолетнем, пострадавшем в результате противоправных
              действий (бездействия), распространение которой запрещено федеральными законами;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.5. информацию, нарушающую требования Федерального закона от 29 декабря 2006 года
              N 244-ФЗ «О государственном регулировании деятельности по организации и проведению
              азартных игр и о внесении изменений в некоторые законодательные акты Российской
              Федерации» и Федерального закона от 11 ноября 2003 года N 138-ФЗ «О лотереях» о
              запрете деятельности по организации и проведению азартных игр и лотерей с
              использованием сети «Интернет» и иных средств связи
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.6. информацию, обеспечивающую возможность совершения действий по переводу
              денежных средств через иностранных поставщиков платежных услуг, включенных в
              перечни, предусмотренные частями 16 и 32 статьи 5.1 Федерального закона от 29
              декабря 2006 года N 244-ФЗ «О государственном регулировании деятельности по
              организации и проведению азартных игр и о внесении изменений в некоторые
              законодательные акты Российской Федерации», частями 11 и 26 статьи 6.2 Федерального
              закона от 11 ноября 2003 года N 138-ФЗ «О лотереях»;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.7. информацию, содержащую предложения о розничной продаже дистанционным способом
              алкогольной продукции, и (или) спиртосодержащей пищевой продукции, и (или)
              этилового спирта, и (или) спиртосодержащей непищевой продукции, розничная продажа
              которой ограничена или запрещена законодательством о государственном регулировании
              производства и оборота этилового спирта, алкогольной и спиртосодержащей продукции и
              об ограничении потребления (распития) алкогольной продукции;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.8. информацию, направленную на склонение или иное вовлечение несовершеннолетних
              в совершение противоправных действий, представляющих угрозу для их жизни и (или)
              здоровья либо для жизни и (или) здоровья иных лиц;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.9. информацию, содержащую предложение о розничной торговле лекарственными
              препаратами, в том числе дистанционным способом, розничная торговля которыми
              ограничена или запрещена в соответствии с законодательством об обращении
              лекарственных средств, и (или) информации, содержащую предложение о розничной
              торговле лекарственными препаратами, в том числе дистанционным способом, лицами, не
              имеющими лицензии и разрешения на осуществление такой деятельности, если получение
              лицензии и разрешения предусмотрено законодательством об обращении лекарственных
              средств;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.10. информацию, содержащую сведения о лицах, в отношении которых в соответствии
              с Федеральным законом от 20 апреля 1995 года N 45-ФЗ «О государственной защите
              судей, должностных лиц правоохранительных и контролирующих органов» и Федеральным
              законом от 20 августа 2004 года N 119-ФЗ «О государственной защите потерпевших,
              свидетелей и иных участников уголовного судопроизводства» обеспечивается
              конфиденциальность;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.11. информацию о способах, методах самодельного изготовления взрывчатых веществ
              и взрывных устройств, незаконного изготовления или переделки оружия, основных частей
              огнестрельного оружия, а равно незаконного изготовления боеприпасов, за исключением
              информации о способах, методах самостоятельного снаряжения патронов к гражданскому
              огнестрельному длинноствольному оружию;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.12. информацию, пропагандирующую нетрадиционные сексуальные отношения и (или)
              предпочтения, педофилию, смену пола.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.13. информацию рекламного или иного коммерческого характера (за исключением
              использования личного кабинета рекламодателя по отдельному соглашению);
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.14. информацию, противоречащую требованиям законодательства или общепризнанным
              нравственным требованиям, в том числе содержащую лексику нецензурного характера,
              непристойные или порнографические тексты и/или изображения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.15. информацию, содержащую угрозы, оскорбления, порочащую честь, достоинство
              и/или деловую репутацию каких-либо лиц или нарушающей тайну частной жизни или иную
              охраняемую законом тайну;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.16. информацию, содержащую описание насилия, жестокого обращения с животными,
              описание способов суицида или подстрекающую к подобным действиям;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.17. информацию, содержащую экстремистские материалы;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.18. информацию, разжигающую любого рода ненависть, рознь или вражду;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.19. информацию о преступной деятельности любых лиц;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.20. информацию, имеющую ложный или мошеннический характер;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              2.8.21. информацию, иным образом нарушающую требования законодательства или права
              любых лиц.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.9. Если в Контенте присутствуют третьи лица, которые является основными лицами, например,
          дают интервью, комментарии и т.п., Блогер обязуется получить согласия у таких лиц на
          использование их изображения и размещения их на Сервисе и по первому требованию
          Администратора предоставить такие согласия.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          2.10. Блогер самостоятельно отвечает за соответствие Контента требованиям действующего
          законодательства и условиям Соглашения.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор не осуществляет
          предварительную модерацию Контента.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор оставляет за
          собой право осуществить выборочную проверку Контента на соответствие Контента действующему
          законодательству Российской Федерации, условиям Соглашения, а также в случае жалоб,
          поступающих от пользователей Сервиса и третьих лиц.</p>
      </li>
    </ol>
  </li>
</ol>

<ol start="3" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>3. Условия размещения Рекламы (Типсов) (монетизация)</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.1. Блогер предоставляет Администратору право монетизировать Контент путем включения в него
          Рекламы (Типсов).
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.2. Блогер обязуется разметить Контент Типсами – отметить в нем специальные места для
          размещения Рекламы.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Если Типсы помещаются на третьих лиц, которые фигурируют в Контенте, Блогер обязуется
          получить согласие таких лиц на рекламирование товаров с их использованием и разрешить с
          ними все финансовые вопросы за свой счет и своими силами без привлечения Администратора.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.3. Реклама (Типсы) размещается в Контенте без какого-либо дополнительного согласия
          Блогера.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.4. Блогер вправе размещать внутри Контента, правообладателем которого он является,
          Рекламу (Типсы), для чего ему необходимо пройти регистрацию в личном кабинете
          рекламодателя.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.5. Блогер имеет право на получение платы от размещения Рекламы (Типсов) в Контенте.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Условия начисление вознаграждения за Рекламу (Типсы), стоимость Клика, порядок выплаты
          вознаграждения указываются в Личном кабинете Блогера.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.6. Вознаграждение Блогера рассчитывается исходя из количества Кликов и стоимости одного
          Клика.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.7. Категорически запрещается использовать какое-либо программное обеспечение или иные
          инструменты, при помощи которых будет завышено количество Кликов.
        </p>

        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          В случае выявления фактов завышения Кликов, выплата вознаграждения Блогеру за такие Клики не
          осуществляется.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Администратор также оставляет за собой право заблокировать Учетную запись Блогера до
          выяснения всех обстоятельств.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.8. Выплачиваемое Блогеру вознаграждение включает в себя НДС по ставке, установленной
          действующим законодательством Российской Федерации.
        </p>

        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          В случае, если Блогер применяет упрощенную систему налогообложения в понятии действующего
          законодательства Российской Федерации, вознаграждение, выплачиваемое ему, НДС не облагается.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          3.9. Администратор ежемесячно формирует и отправляет Блогеру отчет, в котором содержатся
          аналитические данные о количестве Кликов и вознаграждении Блогера за Отчетный период.
        </p>

        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Отчет составляется в течение 7 (Семи) рабочих дней с момента окончания Отчетного периода.
        </p>

        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Отчет предоставляется Блогеру любым доступным способом, в том числе путем отправки на
          электронную почту.
        </p>

        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          В случае отсутствия возражений Блогера на предоставленный отчет в течение 5 (Пяти) рабочих
          дней с момента его получения Блогером, такой отчет считается принятым Блогером и служит
          основанием для выплаты ему вознаграждения.
        </p>
      </li>

    </ol>
  </li>
</ol>

<ol start="4" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>4. Обязанности Блогера</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          <i><b>4.1. Блогер обязан:</b></i>
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.1. Соблюдать условия Соглашения, требования действующего законодательства, а
              также права и законные интересы Администратора, иных пользователей, правообладателей
              Контента и любых третьих лиц;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.2. Использовать Сервис на условиях Соглашения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.3. Воздерживаться от осуществления действий, направленных на дестабилизацию
              работы Сервиса, осуществления попыток несанкционированного доступа к Сервису,
              результатам интеллектуальной деятельности, размещенным на нем, обходу ограничений,
              установленных на Сервисе, от осуществления любых иных действий, нарушающих права
              Администратора, пользователей и/или третьих лиц;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.4. Принимать надлежащие меры для обеспечения сохранности своей Учетной записи.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Блогер несет ответственность за все действия, совершенные на Сервисе под его
              Учетной записью.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Блогер не имеет права передавать, уступать, продавать, передавать в пользование и
              т.п. Учетную запись либо данные от Личного кабинета третьим лицам без
              предварительного согласия Администратора;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.5. Предоставить Администратору всю необходимую, полную и достоверную информацию.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Указание данных третьих лиц, либо их размещение на Сервисе без согласия таких лиц не
              допускается.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Блогер обязуется самостоятельно следить за актуальностью указанных им данных при
              Регистрации, в том числе контактных, банковских, своевременно обновлять их.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Блогер гарантирует Администратору, что представленная при Регистрации контактная
              информация действительно принадлежит ему и любые сообщения, направленные или
              полученные с использованием такой контактной информации, будут считаться
              соответственно направленными или полученными Блогером.
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              В случае изменения контактной информации и не сообщения Блогером об этом
              Администратору, все неблагоприятные последствия, которые могут возникнуть из-за
              этого несет непосредственно Блогер;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              4.1.6. Блогер также несет иные обязанности как предусмотренные настоящим
              Соглашением, так и действующим законодательством территории использования Сервиса.
            </p>
          </li>
        </ol>
      </li>

    </ol>
  </li>
</ol>

<ol start="5" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>5. Права и обязанности Администратора</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          <i><b>5.1. Администратор вправе:</b></i>
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.1.	Самостоятельно определять (изменять) функциональный состав Сервиса, его внешний вид и иные элементы Сервиса.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.2.	Полностью прекратить или приостановить оказание услуг в рамках Сервиса без какого-либо согласия Блогера.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.3.	Решать вопросы, связанные с коммерческим использованием Сервиса, на что Блогер дает ему согласие путем акцепта Соглашения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.4.	В случае нарушения Блогером Соглашения Администратор имеет право приостановить или прекратить доступ Блогера к Сервису в одностороннем порядке, а также блокировать возможность использования Сервиса (блокировать авторизацию и/или IP-адреса, конкретное устройство Блогера);
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.5.	Размещать Рекламу (Типсы) либо предоставлять такое право третьим лицам (рекламодателям) в любом Контенте, а также в самом Сервисе, на что Блогер дает ему согласие путем акцепта Соглашения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.6.	Применять любые действия с целью недопущения несанкционированного доступа к Сервису, размещенному на нем Контенту, дестабилизации работы Сервиса и других действий, нарушающих права и законные интересы Администратора и/или правообладателей Контента;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.7.	В одностороннем порядке вносить изменения и дополнения в Соглашение;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.8.	Осуществлять коммуникацию с Блогером по всем каналам связи, сведения о которых предоставлены Блогером Администратору;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.9.	Осуществлять обработку персональных данных Блогера на условиях, предусмотренных настоящим Соглашением и политикой в области обработки и обеспечения безопасности персональных данных (политикой конфиденциальности);
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.10.	Привлекать любых третьих лиц для исполнения Соглашения без какого-либо согласования с Блогером.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.1.11.	В случае нарушения Блогером условий Соглашения требовать возмещения убытков, компенсации штрафов и расходов, которые могут возникнуть у Администратора из-за действий Блогера.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          <i><b>5.2. Администратор обязан:</b></i>
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              5.2.1.	Обеспечить техническую возможность получения Блогером доступа к Сервису в порядке и на условиях, определенных настоящим Соглашением.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </li>
</ol>


<ol start="6" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>6.	Интеллектуальная собственность</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          6.1.	Администратор является правообладателем Сервиса и всех его элементов, частей, фрагментов, исходного кода, объектного кода, визуального оформления, текстов и иных результатов интеллектуальной деятельности входящих в Сервис либо размещенных на нем.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Все права на результаты интеллектуальной деятельности и средства индивидуализации защищены действующим законодательством Российской Федерации и соответствующими нормами международного права. </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Соглашение не предоставляет Блогеру каких-либо прав на распоряжение вышеуказанными результатами интеллектуальной деятельности.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Любое использование вышеуказанных результатов интеллектуальной деятельности за пределами прав, предоставленных Блогеру по Соглашению, является неправомерным и влечет гражданско-правовую, административную и уголовную ответственность и иные негативные последствия, предусмотренные Соглашением.</p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          6.2.	Блогеру не предоставляется право на скачивание, создание копий, переработку, распространение, передачу иным лицам, продажу или иное отчуждение, публичный показ, публичное исполнение, сообщение в эфир или по кабелю, прокат, ретрансляцию, доведение до всеобщего сведения результатов интеллектуальной деятельности и средств индивидуализации, не принадлежащие Блогеру.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогеру не предоставляется право на размещение результатов интеллектуальной деятельности и средств индивидуализации на товарах, при выполнении работ и оказании услуг, на документации, в объявлениях, на вывесках и в рекламе, а также в сети Интернет, в том числе в доменных именах. </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер не вправе копировать, скачивать, распространять, передавать, транслировать, демонстрировать, продавать, лицензировать, изменять, преобразовывать или каким-либо образом использовать Контент, не принадлежащий ему.</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Запрет, установленный настоящим пунктом, в равной степени распространяется как на полное, так и на частичное/фрагментарное использование результатов интеллектуальной деятельности и средств индивидуализации и их отдельных охраняемых элементов, используемых/размещенных на Сервисе.</p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          6.3.	Блогер обязуется не применять каких-либо средств, методов и способов, прямо или косвенно направленных на обход технических средств защиты используемых на Сервисе.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Нарушение данного пункта признается нарушением авторских прав, в связи с чем к Блогеру могут быть применены меры ответственности по Соглашению и действующему законодательству Российской Федерации. </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          6.4.	Нарушение Блогером настоящего Раздела Соглашения является безусловным и бесспорным основанием для одностороннего отказа Администратора от Соглашения и блокировки доступа к Сервису.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          6.5.	В случае, если у Администратора возникнут какие-либо обязательства перед третьими лицами из-за нарушения Блогером условия настоящего Раздела, в том числе по выплате штрафных санкций, возмещению убытков, выплате компенсаций, Блогер обязан компенсировать Администратору такие суммы в полном объеме.
        </p>
      </li>
    </ol>
  </li>
</ol>


<ol start="7" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>7.	Ограничения использования Сервиса</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          <i>7.1.	Помимо иных ограничений в рамках Соглашения Блогерам запрещается:</i>
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              7.1.1.	Пользоваться Сервисом с помощью автоматических средств, таких как боты, ботнеты и сборщики контента.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              7.1.2.	Обходить ограничения, а также средства защиты, которые действуют на Сервисе.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              7.1.3.	Собирать любую информацию, которая может быть использована для идентификации личности пользователей Сервиса.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              7.1.4.	Беспочвенно обвинять других пользователей: отправлять ложные жалобы, отмечать контент как недопустимый, подавать жалобы на нарушение авторских прав.
            </p>
          </li>
        </ol>
      </li>

    </ol>
  </li>
</ol>


<ol start="8" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>8.	Изменения, улучшения и обновления Сервиса</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          8.1.	Сервис находится в стадии постоянного улучшения, в связи с чем у Сервиса могут как появляться, так исчезать новые функции, опции, инструменты.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Администратор приложит все усилия для того, чтобы доработка, обновление Сервиса осуществлялась максимально комфортным для Блогера образом и не влияла на доступ Блогера к Сервису, при этом в период установки обновлений Сервис может быть временно недоступен, либо могут наблюдаться технические сбои в работе Сервиса.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          8.2.	Любые изменения и улучшения Сервиса производятся Администратором по своему усмотрению без каких-либо согласований с Блогером.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Продолжая пользоваться Сервисом, Блогер выражает свое безоговорочное согласие со всеми изменениями Сервиса и не вправе предъявлять какие-либо претензии Администратору.
        </p>
      </li>

    </ol>
  </li>
</ol>


<ol start="9" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>9.	Ответственность Сторон</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.1.	За нарушение положений Соглашения Блогер несет ответственность, указанную в нем, а также в соответствии с применимым законодательством.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор имеет право ограничить доступ Блогеру к Сервису, заблокировать и/или удалить его Учетную запись, блокировать/удалить размещенный Блогером Контент.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.2.	Администратор не несет ответственности и не предоставляет Блогеру каких-либо гарантий в отношении использования Сервиса, включая соответствие Сервиса ожиданиям Блогера, безошибочную и бесперебойную работу Сервиса.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Сервис и существующие в нем возможности предоставляются Блогеру «как есть» («as is»). </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер самостоятельно принимает на себя любые риски, связанные с использованием Сервиса, в том числе риски возникновения у Блогера в связи с использованием Сервиса каких-либо убытков или потерь, а также причинения какого-либо ущерба или вреда, и Администратор не несет ответственности за это. </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.3.	Блогер гарантирует, что он является совершеннолетним (достиг восемнадцатилетнего возраста).
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае нарушения Блогером данной гарантии - Блогер самостоятельно принимает на себя ответственность за любые последствия, связанные с таким нарушением, а также осознает, что Учетная запись такого Блогера может быть удалена Администратором и/или Администратор вправе прекратить доступ Блогера к Сервису.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.4.	Администратор не несет ответственности за содержание размещаемого Блогером Контента, а также иной информации, включая информацию, размещенную третьими лицами, в т.ч. пользовательские тексты и комментарии.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.5.	Администратор не несет ответственности за технические сбои и неполадки в работе пользовательских устройств, иного оборудования, используемого Блогером, сетей связи, программного обеспечения, серверного оборудования, сервисов электронной почты, а также за любые убытки, которые могут быть вызваны такими сбоями и неполадками.
        </p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.6.	Администратор не несет ответственности и не возмещает Блогеру никакие денежные средства в случаях несанкционированного использования Учетной записи Блогера третьими лицами.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер самостоятельно несет полную ответственность за сохранение в тайне от третьих лиц его регистрационных данных (логина, пароля), а также за недопуск третьих лиц к каналам связи, посредством которых возможно изменение или восстановление регистрационных данных Блогера.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.7.	Администратор не несет ответственности перед третьими лицами и иными пользователями за какие-либо действия Блогера, включая размещение или предоставление Блогером Контента, какой-либо иной информации, в том числе рекламного характера, в Сервисе.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер самостоятельно несет полную ответственность за любые действия, совершенные им с использованием его Учетной записи, а также за любые последствия, которые могло повлечь или повлекло подобное использование.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.8.	Администратор не несет ответственности за достоверность Рекламы (Типсов) или иной информации, которая демонстрируется при просмотре Контента, а также за качество рекламируемых пользователям товаров, работ и услуг.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогеру запрещается размещать в Контенте информацию, содержащую рекламные материалы, спонсорские материалы либо упоминания о каких-либо товарах, услугах, торговых знаках, знаках обслуживания, продакт-плайсмент, интегрированную рекламу и т.п. без размещения Типсов (п.3.2 Соглашения).</p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае, если Блогером будет размещена вышеуказанная информация в Контенте без Типсов, Администратор оставляет за собой право заблокировать Контент Блогера. </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае, если к Администратору будут предъявлены какие-либо претензии или требования, в том числе об уплате штрафов и компенсаций, в связи с действиями, указанными выше, Блогер обязуется возместить такие штрафы и компенсации в добровольном порядке в течение 10 (Десяти) календарных дней с момента получения соответствующего требования от Администратора.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.9.	Стороны признают и подтверждают, что в случае перехода Блогера по каким-либо гиперссылкам, размещенным на Сервисе другими пользователями, Администратор не несет какой-либо ответственности за доступность интернет-ресурсов по таким ссылкам и не контролирует их содержание, в связи с чем не несет ответственности за любые последствия перехода Блогера по таким ссылкам.
        </p>


      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.10.	Блогер гарантирует, что:
        </p>
        <ol>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              9.10.1.	Действует на законных основаниях, обладает всеми необходимыми правами на заключение Соглашения;
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              9.10.2.	Обладает всеми необходимыми правами на использование Контента, в том числе используемых в Контенте результатов интеллектуальной деятельности третьих лиц, а также то, что имеет право на размещение Контента на Сервисе и использования его в соответствии с условиями Соглашения;
            </p>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              Блогер единолично несет всю ответственность за размещение и использование Контента и используемых в нем результатов интеллектуальной деятельности третьих лиц.
            </p>
          </li>
          <li>
            <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
              9.10.3.	Не нарушает своими действиями законные права и интересы третьих лиц (включая, но не ограничиваясь, авторские, смежные, патентные права, право на неприкосновенность частной жизни, на защиту чести, достоинства и доброго имени, право на изображение гражданина и т.д.) и действующее российское законодательство.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.11.	Если Блогер не является правообладателем Контента либо используемых в нем результатов интеллектуальной деятельности третьих лиц, Блогер самостоятельно согласовывает с правообладателями Контента, используемых в нем результатов интеллектуальной деятельности третьих лиц, возможность их использования и размещения на Сервисе.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер самостоятельно производит все расчеты с авторами и обладателями авторских и смежных прав на Контент, либо результаты интеллектуальной деятельности которых были включены в Контент.</p>


        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Блогер также самостоятельно производит выплаты в пользу какой-либо организации по управлению правами на коллективной основе (по коллективному управлению авторскими или смежными правами), если действующим законодательством предусмотрены или будут предусмотрены такие выплаты.</p>


        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае предъявления каких-либо претензий и/или исков к Администратору, основанных на нарушении авторских прав третьими лицами, либо о выплате таким лицам какого-либо вознаграждения в связи с использованием их результатов интеллектуальной деятельности в Контенте Блогера, Блогер самостоятельно (без привлечения Администратора) и за свой счет разрешает такие претензии и иски, а в случае, если на Администратора будут возложены какие-либо выплаты по таким претензиям и/или искам в пользу третьих лиц, Блогер обязуется возместить Администратору такие выплаты и возникшие убытки в полном объеме.</p>

      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          9.12.	Для защиты своих прав Администратор имеет право предоставлять третьим лицам Соглашение, а также данные Блогера (данные Учетной записи и регистрационные данные) в связи с чем, Блогер дает Администратору согласие на осуществление таких действий.
        </p>

      </li>
    </ol>
  </li>
</ol>

<ol start="10" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>10.	Персональные данные</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          10.1.	Все правила и условия обработки данных Блогера, включая персональные данные, определяются в отдельном документе, размещенном в Сервисе.
        </p>
      </li>

    </ol>
  </li>
</ol>

<ol start="11" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>11.	Разрешение споров</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          11.1.	Все споры и разногласия, возникающие при исполнении Соглашения, использовании Сервиса, решаются Сторонами путем переговоров и в претензионном порядке.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          11.2.	Претензии могут направляться друг другу с использованием адресов электронной почты, указанных каждой из сторон.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          11.3.	Если достичь согласия путем переговоров и в претензионном порядке не представляется возможным, споры решаются в судебном порядке в соответствии с действующим законодательством Российской Федерации, по месту нахождения Администратора.
        </p>
      </li>

    </ol>
  </li>
</ol>


<ol start="12" style="margin-top: 0.41cm;">
  <li>
    <h1 class="western" align="left"><b><i>12.	Заключительные положения</i></b></h1>
    <ol>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.1.	Недействительность какого-либо условия Соглашения не влечет недействительности прочих его условий. В этом случае Стороны руководствуются Соглашением без учета условия, не имеющего юридической силы.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.2.	Во всем остальном, что не предусмотрено Соглашением, Стороны руководствуются положениями действующего законодательства Российской Федерации.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.3.	Соглашение заключено на неопределенный срок. Прекращение или временная приостановка использования Сервиса Блогером без удаления Учетной записи, не влечет прекращения действия Соглашения.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.4.	Администратор оставляет за собой право изменять условия настоящего Соглашения в одностороннем порядке в любое время по своему усмотрению.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          В случае внесения изменений в настоящее Соглашение такие изменения распространяются на всех Блогеров с момента внесения изменений в Соглашение, если иное не предусмотрено Соглашением.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Обновленное Соглашение распространяются на всех Блогеров с момента внесения изменений в Соглашение.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Продолжая использовать Сервис после внесения изменений в Соглашение, Блогер подтверждает свое согласие с обновленным Соглашением.
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.5.	По любым вопросам работы Сервиса, а также для направления юридически значимых сообщений, в том числе, претензий, Блогеры могут обращаться к Администратору по адресу электронной почты: <a href="mailto:info@atips.ru">info@atips.ru</a>.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Претензии должны быть обоснованными и подтверждаться доказательствами (документам).
        </p>
      </li>
      <li>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          12.6.	Положения настоящего Соглашения применяются исходя из версии Сервиса и его функциональных возможностей.
        </p>
        <p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
          Если Сервисом не предусмотрены те или иные функциональные возможности, ссылки на которые имеются в Соглашении, положения таких пунктов не применяются Сторонами до момента появления соответствующих функциональных возможностей.
        </p>
      </li>
    </ol>
  </li>
</ol>






</li>`;

export const PRIVACY_POLICY = `<p align="center">
</p>
<p>
Дата публикации: «__» октября 2023  г.
</p>
<p>
Настоящая политика  конфиденциальности (далее – «Политика») определяет
порядок Обработки  персональных данных и меры по обеспечению их
безопасности, предпринимаемые  Оператором.
</p>
<p>
Соглашаясь с настоящей  Политикой, Блогер подтверждает, что:
</p>
<p>
−          является дееспособным лицом;
</p>
<p>
−          ознакомился с Политикой и выражает свое  безусловное согласие с
ней и принимает на себя указанные в ней права и  обязанности;
</p>
<p>
−          предоставляет достоверные и достаточные  Персональные данные,
поддерживает такие данные в актуальном состоянии;
</p>
<p>
−          обладает всеми необходимыми правами для  регистрации и
использования Сайта.
</p>
<p align="center">
<strong>1.</strong>
<strong>
    Термины и определения,  использующиеся в Политике.
</strong>
</p>
<p>
<strong>Персональные данные</strong>- любая информация, относящаяся к  прямо
или косвенно определенному или определяемому субъекту персональных данных  —
Блогеру.
</p>
<p>
<strong>Оператор</strong>- общество с ограниченной ответственностью
«ЭйТипс», ОГРН  1227700295943, адрес: 109382,  г. Москва, ул Нижние Поля, д.
31, стр. 1, фактический адрес: 109382, г. Москва, ул. Нижние  Поля, д. 31,
стр. 1, самостоятельно или  совместно с другими лицами организующее и/или
осуществляющее  Обработку персональных данных, а также определяющее цели
Обработки  персональных данных, состав Персональных данных, подлежащих
обработке, действия  (операции), совершаемые с Персональными данными.
</p>
<p>
<strong>Обработка персональных данных</strong> – это любое действие
(операция) или  совокупность действий (операций) с Персональными данными,
совершаемых с  использованием средств автоматизации или без использования
таких средств с  Персональными данными, включая сбор, запись,
систематизацию, накопление,  хранение, уточнение (обновление, изменение),
извлечение, использование,  передачу (распространение, предоставление,
доступ), обезличивание,  блокирование, удаление, уничтожение Персональных
данных.
</p>
<p>
<strong>Автоматизированная Обработка персональных данных</strong> –
Обработка персональных данных с  помощью средств вычислительной техники.
</p>
<p>
<strong>Блокирование персональных данных</strong> – временное прекращение
Обработки  персональных данных, за исключением случаев, если обработка
необходима для  уточнения Персональных данных.
</p>
<p>
<strong>Обезличивание персональных данных</strong> – действия, в результате
которых  становится невозможным без использования дополнительной информации
определить  принадлежность Персональных данных Блогеру.
</p>
<p>
<strong>Предоставление персональных данных</strong> – действия, направленные
на  раскрытие персональных данных определенному лицу или определенному кругу
лиц.
</p>
<p>
<strong>Распространение персональных данных</strong> – действия,
направленные на  раскрытие персональных данных неопределенному кругу лиц.
</p>
<p>
<strong>Блогер</strong> — физическое лицо, зарегистрированное в качестве
индивидуального  предпринимателя, или юридическое лицо, которые прошли
регистрацию и использует  Сайт для размещения контента (аудиовизуальных
произведения -  видеороликов и иных подобного рода произведения).
</p>
<p>
<strong>Сайт</strong> — <a href="https://creator.atips.io/" target="_blank">https://creator.atips.io</a><u></u>.
</p>
<p>
<strong>Cookies</strong>— файл с настройками и  другой информацией, который
сохраняется на компьютерах Блогеров.
</p>
<p>
<strong>Сетевой адрес (IP-адрес)</strong> - идентификатор в сети  передачи
данных, определяющий при оказании телекоммуникационных услуг связи
абонентский терминал или иные средства связи, входящие в информационную
систему.
</p>
<p>
<strong>Уничтожение персональных данных</strong> – действия, в  результате
которых становится невозможным восстановить содержание Персональных  данных
в ИСПДн и (или) в результате которых уничтожаются материальные носители
Персональных данных.
</p>
<p>
<strong>ИСПДн</strong> – Информационная система персональных данных.
Совокупность содержащихся в базах данных Персональных данных и
обеспечивающих  их обработку информационных технологий и технических
средств.
</p>
<p>
<strong>Трансграничная передача Персональных данных</strong> — передача
Персональных данных на территорию иностранного государства органу власти
иностранного государства, иностранному физическому или иностранному
юридическому лицу.
</p>
<p>
<strong>Закон</strong> - Федеральный закон от  27.07.2006 г. № 152-ФЗ «О
персональных данных»
</p>
<p align="center">
<strong>2.</strong> <strong>Область действия  Политики</strong>
</p>
<p>
Политика действует в  отношении Персональных данных, которые Оператор может
получить от Блогера в процессе  использования Сайта и исполнения соглашений
или договоров с Блогером.
</p>
<p>
Политика применяется в отношении  Персональных данных:
</p>
<p>
- сотрудников Блогера;
</p>
<p>
- непосредственного Блогера –  физического лица.
</p>
<p>
<strong>Перечень Персональных данных, обрабатываемых  Оператором</strong>
</p>
<p>
Для доступа к Сайту и  заключения соглашения или договора, Блогер
предоставляет следующие Персональные  данные:
</p>
<p>
<a name="bookmark=id.gjdgxs"></a>- ФИО или ФИО представителя;
</p>
<p>
- контактный номер телефона;
</p>
<p>
- адрес электронной почты;
</p>
<p>
- ИНН;
</p>
<p>
- ОГРН ИП;
</p>
<p>
- адрес регистрации (для индивидуальных  предпринимателей);
</p>
<p>
- банковские реквизиты.
</p>
<p>
Блогер предоставляет Оператору  конкретное, информированное, сознательное и
однозначное согласие на Обработку  своих Персональных данных в следующем
порядке:
</p>
<p>
−          для Персональных данных, указанных Блогером  при регистрации на
Сайте, – путем заполнения формы регистрации, проставлении  галочки в поле
«Соглашаюсь на обработку моих персональных данных в соответствии  с
политикой конфиденциальности и принимаю условия соглашения» (или ином
аналогичном поле) и нажатии кнопки «Зарегистрироваться»;
</p>
<p>
−          для Персональных данных, которые были  отредактированы Блогером в
личном кабинете Сайта, - путем внесения обновленных  данных в профиле
Блогера и нажатия кнопки «Сохранить изменения» (или иной  аналогичной
кнопки).
</p>
<p>
Предоставляя Оператору согласие на  Обработку персональных данных одним из
способов, указанных  выше, Блогер подтверждает, что он действует свободно,
своей волей и в своих  интересах.
</p>
<p>
В случаях, если при  регистрации на Сайте/редактировании информации в личном
кабинете Сайта были  указаны Персональные данные сотрудников Блогера,
последний подтверждает и  гарантирует, что получил от таких сотрудников все
необходимые согласия на  Обработку их персональных данных, в том числе, в
указанных целях.
</p>
<p>
Оператор не обязан  запрашивать согласие непосредственно у сотрудника
Блогера, но вправе запросить  такое согласие у Блогера.
</p>
<p>
Блогер обязуется  предоставить согласие на Обработку персональных данных
своих сотрудников в  сроки, обозначенные Оператором.
</p>
<p>
Оператор полагается на достоверность  предоставленных Блогером Персональных
данных. <a name="bookmark=id.30j0zll"></a>
</p>
<p>
<a name="bookmark=id.1fob9te"></a>При использовании Блогером Сайта Оператор
анализирует следующие  данные:
</p>
<p>
−          HTTP-заголовок,  Сетевой адрес, файлы cookies, информацию об
аппаратном и программном  обеспечении устройства Блогера;
</p>
<p>
−          информацию об активности Блогера во время  использования Сайта
(данные о посещенных страницах).
</p>
<p>
Оператор не хранит в  базах данных, связанных с персональными данными
Блогеров, анализируемую  информацию.
</p>
<p>
Оператор не собирает  специальные категории Персональных данных (расовое
происхождение, политические взгляды, информация о  здоровье и биометрические
данные) и настоятельно просит Блогера не  предоставлять подобные данные на
Сайте либо сотрудникам Оператора.
</p>
<p>
При предоставлении  подобных данных Оператор не несет ответственности за их
обработку, хранение и  передачу третьим лицам.
<a name="bookmark=id.3znysh7">
</a>
</p>
<p>
Трансграничная передача  персональных данных Оператором не осуществляется.
</p>
<p align="center">
<a name="_heading=h.2et92p0"></a><strong>3.</strong>
<strong>
    Цели Обработки  персональных данных
</strong>
</p>
<p>
Оператор Обрабатывает  персональные данные Блогера в целях:
</p>
<p>
−          регистрации Блогера на Сайте;
</p>
<p>
−          идентификации Блогера на Сайте;
</p>
<p>
−          авторизации Блогера на Сайте;
</p>
<p>
−          заключения с Блогером пользовательского соглашения  или договора;
</p>
<p>
−          исполнения пользовательского соглашения или  договора с Блогером;
</p>
<p>
−          предоставления информации Блогеру о Сайте и  услугах Оператора;
</p>
<p>
−          для защиты прав Блогера и прав Оператора;
</p>
<p>
−          для организации финансовых расчетов с Блогером;
</p>
<p>
−          для совершенствования и улучшения Сайта;
</p>
<p>
−          для выявления угроз безопасности Сайта.
</p>
<p align="center">
<strong>4.</strong>
<strong>
    Правовые основания обработки персональных данных
</strong>
</p>
<p>
Персональные данные Блогеров,  его сотрудников, обрабатываются Оператором на
основании:
</p>
<p>
−          Конституции Российской Федерации;
</p>
<p>
−          Гражданского кодекса Российской Федерации;
</p>
<p>
−          Устава ООО «ЭйТипс»;
</p>
<p>
−          Пользовательских соглашений или договоров с  Блогером;
</p>
<p>
−          Согласия на обработку персональных данных (пп.1  ч.1 ст. 6
Закона).
</p>
<p align="center">
<strong>5.</strong>
<strong>
    Порядок, условия и способы Обработки персональных данных
</strong>
</p>
<p>
Отказ Блогера от  предоставления согласия на Обработку его персональных
данных влечет за собой  невозможность достижения целей Обработки
персональных данных (п.3 Политики) за  исключением случаев, установленных
действующим законодательством Российской  Федерации.
</p>
<p>
Оператор осуществляет  Обработку персональных данных Блогера
автоматизированным и неавтоматизированным  способом.
</p>
<p>
Персональные данные Блогера  обрабатываются и хранятся на виртуальных
вычислительных серверах, арендуемых у  ООО «Яндекс.Облако».
</p>
<p>
Оператор может  передавать Персональные данные органам дознания и следствия,
иным  уполномоченным органам по основаниям, предусмотренным действующим
законодательством Российской Федерации.
</p>
<p>
Обработка Персональных  данных производится на основе соблюдения принципов:
</p>
<p>
−          законности целей и способов Обработки  персональных данных;
</p>
<p>
−          соответствия целей Обработки персональных  данных целям, заранее
определенным и заявленным при сборе Персональных данных;
</p>
<p>
−          соответствия объема и характера обрабатываемых  Персональных
данных, способов Обработки персональных данных заявленным целям  Обработки
персональных данных;
</p>
<p>
−          достоверности Персональных данных, их  достаточности для целей
обработки, недопустимости Обработки персональных  данных, избыточных по
отношению к целям, заявленным при сборе Персональных  данных;
</p>
<p>
−          недопустимости объединения созданных для  несовместимых между
собой целей баз данных, содержащих Персональные данные;
</p>
<p>
−          хранения Персональных данных в форме,  позволяющей определить
Блогера, не дольше, чем этого требуют цели их обработки;
</p>
<p>
−          уничтожения по достижении целей Обработки  персональных данных
или в случае утраты необходимости в их достижении.
</p>
<p align="center">
<strong>6.</strong>
<strong>
    Сроки Обработки персональных данных и их уничтожение
</strong>
</p>
<p>
Обработка персональных  данных осуществляется Оператором:
</p>
<p>
−          До момента достижения целей (п.3 Политики);
</p>
<p>
−          До момента удаления Блогером учетной записи на  Сайте;
</p>
<p>
−          До момента отзыва Блогером (его сотрудником)  согласия на
Обработку персональных данных;
</p>
<p>
−          До момента окончания срока действия согласия на  Обработку
персональных данных.
</p>
<p>
По достижению сроков  Обработки персональных данных такие данные подлежат
уничтожению, если иное не  предусмотрено Законом.
</p>
<p align="center">
<strong>7.</strong> <strong>Права и обязанности</strong>
</p>
<p>
<strong><em>Оператор персональных  данных вправе:</em></strong>
</p>
<p>
−          получать от Блогера достоверные Персональные  данные, необходимые
для достижения целей Обработки персональных данных;
</p>
<p>
−          предоставлять Персональные данные Блогера  третьим лицам, если
это предусмотрено действующим законодательством Российской  Федерации и
Политикой;
</p>
<p>
−          отказывать в предоставлении Персональных данных  в
предусмотренных законодательством Российской Федерации случаях;
</p>
<p>
−          осуществлять Обработку персональных данных без  согласия Блогера
при наличии оснований, указанных в Законе;
</p>
<p>
−          самостоятельно определять состав  и перечень мер, необходимых и
достаточных для обеспечения выполнения  обязанностей, предусмотренных
действующим законодательство Российской  Федерации и Политикой.
</p>
<p>
<strong><em>Оператор персональных  данных обязуется:</em></strong>
</p>
<p>
−          предоставлять Блогеру или его законному  представителю по их
просьбе информацию, касающуюся Обработки персональных данных  Блогера, а
также предоставить возможность ознакомления с такими Персональными  данными;
</p>
<p>
−          отвечать на обращения и запросы  Блогера и его законных
представителей в соответствии  с требованиями Закона;
</p>
<p>
−          прекратить Обработку персональных данных в  случаях, указанных в
Политике и Законе;
</p>
<p>
−          внести необходимые изменения, уничтожить или  блокировать
Персональные данные в случае предоставления Блогером неполных,  устаревших,
недостоверных или незаконно полученных Персональных данных, а также
уведомить о своих действиях Блогера;
</p>
<p>
−          выполнять требования законодательства Российской  Федерации.
</p>
<p>
<strong><em>Блогер имеет право:</em></strong>
</p>
<p>
−          получать информацию, касающуюся обработки его  Персональных
данных, за исключением случаев, предусмотренных федеральными  законами, в
том числе:
</p>


<ul>
<li>о подтверждении факта Обработки персональных  данных Оператором;</li>
<li>о правовых основания и целях Обработки  персональных данных;</li>
<li>о целях  и применяемых Оператором способах Обработки персональных
данных;</li>
<li>о наименование и месте нахождения Оператора,  сведениях о лицах
(за исключением работников оператора), которые имеют доступ к  Персональным
данным или которым могут быть раскрыты Персональные данные на  основании
договора с Оператором или на основании Закона;</li>
<li>об обрабатываемых Персональных данных,  относящиеся к Блогеру,
источнике их получения, если иной порядок представления  таких данных не
предусмотрен Законом;</li>
<li>о сроках Обработки персональных данных, в том  числе сроках их
хранения;</li>
<li>о порядке осуществления Блогером прав,  предусмотренных Законом;</li>
<li>информацию об осуществленной или о  предполагаемой Трансграничной
передаче данных;</li>
<li>о наименовании или фамилии, имени, отчестве и  адресе лица,
осуществляющего Обработку персональных данных по поручению  Оператора, если
обработка поручена или будет поручена такому лицу;</li>
<li>информацию о способах исполнения Оператором  обязанностей,
установленных статьей 18.1 Закона;</li>
<li>иных сведениях, предусмотренных Законом или  другими федеральными
законами;</li>
</ul>






<p>
−          требовать от Оператора уточнения его  Персональных данных, их
блокирования или уничтожения в случае, если Персональные  данные являются
неполными, устаревшими, неточными, незаконно полученными или  не являются
необходимыми для заявленной цели обработки, а также  принимать
предусмотренные законом меры по защите своих прав;
</p>
<p>
−          обжаловать в уполномоченный орган по защите прав  субъектов
персональных данных или в судебном порядке неправомерные действия или
бездействия при обработке его Персональных данных;
</p>
<p>
−          на осуществление иных прав,  предусмотренных законодательством
Российской Федерации.
</p>
<p>
<strong><em>Блогер обязан:</em></strong>
</p>
<p>
−          передавать достоверные Персональные данные;
</p>
<p>
−          выполнять требования законодательства Российской  Федерации.
</p>
<p align="center">
<strong>8.</strong>
<strong>
    Актуализация и исправление Персональных данных, ответы на  запросы
    Блогера на доступ к Персональным данным
</strong>
</p>
<p>
Блогер имеет право на  получение сведений, указанных в <a href="https://www.consultant.ru/document/cons_doc_LAW_61801/a15bab6050028753706f22c32fe60627a7be79f9/" target="_blank">части 7</a> ст.14 Закона, за исключением случаев,  предусмотренных <a href="https://www.consultant.ru/document/cons_doc_LAW_61801/cd0d49f816bca2fb01994515ed3223a96dd38629/" target="_blank">частью 8</a> ст. 14 Закона (далее – «Сведения»).
</p>
<p>
Сведения  предоставляются Блогеру в доступной форме и в них не включаются
персональные данные, относящиеся к  другим субъектам персональных данных за
исключением случаев, когда имеются  законные основания для раскрытия таких
персональных данных.
</p>
<p>
Сведения предоставляются Блогеру или  его представителю в течение 10 рабочих
дней с  момента обращения либо получения запроса от Блогеру или его
представителя.
</p>
<p>
Данный срок может быть  продлен Оператором, но не более чем на пять рабочих
дней.
</p>
<p>
В случае продления  срока, Оператор направляет Блогеру мотивированное
уведомление с указанием  причин продления срока предоставления Сведений.
</p>
<p>
Запрос должен содержать:
</p>
<p>
−          номер основного документа, удостоверяющего  личность субъекта
персональных данных или его представителя;
</p>
<p>
−          сведения о дате выдачи указанного документа и  выдавшем его
органе;
</p>
<p>
−          сведения, подтверждающие участие субъекта  персональных данных в
отношениях с Оператором (номер договора, дата заключения  договора, условное
словесное обозначение и (или) иные сведения), либо сведения,  иным образом
подтверждающие факт обработки персональных данных Оператором;
</p>
<p>
−          подпись субъекта персональных данных или его  представителя.
</p>
<p>
Если Блогеру были  предоставлены полностью запрошенные Сведения по его
первоначальному запросу,  Блогер вправе обратиться к Оператору с повторным
запросом не ранее чем через 30  (тридцать) дней.
</p>
<p>
Если Сведения были  предоставлены Блогеру в не полном объеме по
первоначальному запросу, Блогер  вправе подать повторный запрос с
обоснованием его повторного направления.
</p>
<p>
Оператор вправе  отказать Блогеру по предоставлению Сведений по повторному
запросу, если такой  запрос не соответствует вышеописанному.
</p>
<p>
Оператор вправе  отказать Блогеру в предоставлении Сведений. Отказ должен
быть мотивированный.  Отказ в предоставлении Сведений направляется Блогеру в
срок не превышающий 10  (десяти) дней с момента получения запроса Блогера.
</p>
<p>
Вышеуказанный срок  может быть продлен Оператором на срок не более 5 (пяти)
дней с обоснованием  причин.
</p>
<p>
Оператор рассматривает  все запросы Блогера в порядке, указанном выше.
</p>
<p>
Оператор вносит изменения в  Персональные данные Блогера в течение 7 (семи)
дней с момента обращения Блогера  с обращением о неполных, неточных или
неактуальных Персональных данных.
</p>
<p>
Оператор уничтожает незаконно  полученные Персональные данные или данные,
которые не является необходимыми для  целей обработки, в течение 7 (семи)
дней с момента обращения Блогера с  соответствующим обращением.
</p>
<p>
Оператор блокирует Персональные  данные, обработка которых является
неправомерной, либо неточные Персональные  данные на период проверки.
</p>
<p>
Блокировка Персональных данных  производится на основании запроса Блогера
либо уполномоченного органа по защите  прав субъектов персональных данных.
</p>
<p>
Оператор прекращает неправомерную  Обработку персональных данных в течение 3
(трех) рабочих дней с момента факта  ее выявления.
</p>
<p>
Если обеспечить правомерность  Обработки персональных данных невозможно,
Оператор в срок, не превышающий 10  (десяти) рабочих дней с момента
выявления неправомерной Обработки персональных  данных, уничтожает такие
Персональные данные или обеспечивает их уничтожение.
</p>
<p>
Оператор уточняет Персональные  данные на основании обращения Блогера в
течение 7 (семи) рабочих дней с момента  подтверждения факта неточных
Персональных данных, затем снимает блокировку с  таких Персональных данных.
</p>
<p>
Если Закон содержит иные сроки и порядок  рассмотрения обращений и запросов
Блогера, чем указанные в Политики,  применяются порядок и сроки, указанные в
Законе.
</p>
<p>
Обращения, запросы могут  направляться Блогером в адрес Оператора по адресу:
109382, г. Москва, ул Нижние Поля, д. 31, стр. 1,  или в форме электронного
документа, по адресу электронной почты:
<a href="mailto:support@atips.ru">support@atips.ru</a>, с указанием в теме письма:  «обращение/запрос по персональным данным».
</p>
<p align="center">
<strong>9.</strong> <strong>Защита Персональных  данных</strong>
</p>
<p>
Оператор организует  Обработку персональных данных с соблюдением условий
конфиденциальности и  безопасности.
</p>
<p>
Обеспечение безопасности  Обработки персональных данных достигается в том
числе путем  разработки и своевременной актуализации
организационно-распорядительной  документации, технических мер защиты,
соответствующих условиям Обработки  персональных данных.
</p>
<p>
Оператором разработаны и  внедрены необходимые организационные и технические
меры для обеспечения  безопасности Персональных данных от
несанкционированного доступа, уничтожения,  изменения и других
несанкционированных действий.
</p>
<p>
К таким мерам относятся:
</p>
<p>
−          назначение лица, ответственного за организацию  Обработки
персональных данных;
</p>
<p>
−          утверждение и своевременная актуализация  документов,
определяющих политику в отношении Обработки персональных данных,
организационных и технических мер, направленных на предотвращение и
выявление  нарушений законодательства;
</p>
<p>
−          разработка модель угроз безопасности  Персональных данных для
ИСПДн;
</p>
<p>
−          подписание сотрудниками Оператора, допущенные к  Обработке
персональных данных, соглашений о неразглашении;
</p>
<p>
−          разработка и внедрение настройки безопасности  для системного и
прикладного программного обеспечения ИСПДн;
</p>
<p>
−          выбор, внедрение и настройка средств защиты  информации ИСПДн;
</p>
<p>
−          проведение внутреннего аудита на соответствие  Обработки
персональных данных законодательству Российской Федерации;
</p>
<p>
−          проведение периодической оценки эффективности  принимаемых мер по
обеспечению безопасности Персональных данных;
</p>
<p>
−          проведение периодической оценки вреда, который  может быть
причинен Блогеру.
</p>
<p align="center">
<strong>10.</strong>
<strong>
    Передача Персональных  данных третьим лицам
</strong>
</p>
<p>
Оператор может  передавать Персональные данные третьим лицам, к которым
относятся:
</p>
<p>
−          провайдер облачных услуг, участвующий в процессе  хранения,
передачи и Обработки персональных данных;
</p>
<p>
−          государственные органы, правоохранительные  органы и судебные
органы Российской Федерации.
</p>
<p>
Регистрируясь на Сайте,  принимая условия пользовательского соглашения и
Политики, Блогер дает Оператору  согласие на предоставление Персональных
данных вышеуказанным третьим лицам.
</p>
<p>
Оператор информирует  Блогера, что в случае отзыва согласия на
предоставление Персональных данных  вышеуказанным третьим лицам, Оператор не
сможет оказывать услуги Блогеру и  доступ к Сайту может быть ограничен
Оператором.
</p>
<p align="center">
<strong>11.</strong> <strong>Использование</strong>
<strong>
    файлов Cookies
</strong>
</p>
<p>
Оператор использует  следующие типы файлов Cookies:
</p>
<p>
−          технические файлы Cookies: необходимы для работы  Сайта, они
позволяют идентифицировать аппаратное и программное обеспечение  Блогера, в
том числе тип и версию используемого браузера;
</p>
<p>
−          статистические/аналитические файлы Cookie:  позволяют
распознавать Блогеров, подсчитывать их количество и собирать  информацию о
посещенных веб-страницах;
</p>
<p>
−          функциональные файлы Cookie: позволяют  предоставлять
определенные функции, чтобы облегчить использование сайта для  Блогера,
например, сохраняют языковые и региональные предпочтения;
</p>
<p>
−          сторонние Cookies: собирают информацию о  Блогерах, источниках
трафика, посещенных страницах и рекламе.
</p>
<p>
Файлы Cookies используются для  запоминания предпочтений Блогера на Сайте.
</p>
<p>
В случае отказа Блогера от  использования файлов Cookies, часть функций
Сайта может быть недоступна.
</p>
<p>
Оператор не требует обязательного  согласия на установку файлов Cookies на
устройство, Блогер вправе отключить эту  опцию в настройках браузера.
</p>
<p>
Оператор вправе передавать  информацию из файлов Cookies третьим лицам для
целей, указанных в Политике.
</p>
<p align="center">
<strong>12.</strong> <strong>Изменение Политики</strong>
</p>
<p>
Оператор оставляет за собой право в  одностороннем порядке вносить изменения
в Политику, в том числе, по причине  изменения законодательства Российской
Федерации и при необходимости приведения  Политики в соответствие с такими
изменениями.
</p>
<p>
Актуальная версия политики  размещается на
<a href="https://creator.atips.io/" target="_blank">Сайте</a>.
</p>
<p>
Продолжая использовать Сайт после  внесения изменений в <a href="https://creator.atips.io/privacy" target="_blank">Политику</a>, <a href="https://creator.atips.io/legal" target="_blank">Блогер
соглашается с условиями</a> обновленной Политики в полном объеме.
</p>`;
