import { nativeMapBy } from "../components/PersonalArea/scripts/mapBy";

const initialPolitics = {
  list: [],
  pagination: {},
};

const initialLocation = {
  list: [],
  map: {},
};

export const PoliticsReducer = (state = initialPolitics, action) => {
  switch (action.type) {
    case "GET_POLITICS":
      const data = action.politic.data;
      return {
        list: data.items,
        pagination: {
          max_elements: data.total,
          max_pages: data.pages,
          page: data.page,
          limit: data.size,
        },
      };
    case "POST_DOMAIN_POLITICS":
      state.list.push(action.politic.data);
      return state;
    case "PUT_DOMAIN_POLITICS":
      const newArray = state.list.map((elem) => {
        if (elem.id === action.politic.data.id) {
          return action.politic.data;
        } else return elem;
      });
      return { ...state, list: newArray };
    case "DELETE_POLITIC":
      const filtererState = state.list.filter(
        (item, index) => item.id !== action.id
      );
      return { ...state, list: filtererState };
    default:
      return state;
  }
};

export const PoliticsLocationReducer = (state = initialLocation, action) => {
  switch (action.type) {
    case "GET_POLITICS_LOCATION":
      const data = action.frame.data.attributes;
      const mappedData = nativeMapBy(data, "id");
      return {
        list: data,
        map: mappedData,
      };
    default:
      return state;
  }
};
