const initialAds = {
  list: [],
  pagination: {},
};

const initialExternalSellersList = {
  active: initialAds,
  review: initialAds,
  blocked: initialAds,
};

export const AdvertismentReducer = (
  state = initialExternalSellersList,
  action
) => {
  switch (action.type) {
    case "GET_ALL_ACTIVE_AD": {
      const data = action.ad.data;
      return {
        ...state,
        active: {
          list: data.items,
          pagination: {
            max_elements: data.total,
            max_pages: Math.ceil(data.total / data.size),
            page: data.page,
            limit: data.size,
          },
        },
      };
    }
    case "GET_ALL_REVIEW_AD": {
      const data = action.ad.data;
      return {
        ...state,
        review: {
          list: data.items,
          pagination: {
            max_elements: data.total,
            max_pages: Math.ceil(data.total / data.size),
            page: data.page,
            limit: data.size,
          },
        },
      };
    }
    case "GET_ALL_BLOCKED_AD": {
      const data = action.ad.data;

      return {
        ...state,
        blocked: {
          list: data.items,
          pagination: {
            max_elements: data.total,
            max_pages: Math.ceil(data.total / data.size),
            page: data.page,
            limit: data.size,
          },
        },
      };
    }
    default:
      return state;
  }
};

const initialExternalSellersTotal = {
  1: 0,
  2: 0,
  3: 0,
};

export const AdvertismentTotalReducer = (
  state = initialExternalSellersTotal,
  action
) => {
  switch (action.type) {
    case "GET_ACTIVE_AD_TOTAL": {
      const data = action.ad.data;
      return {
        ...state,
        2: data.total,
      };
    }
    case "GET_REVIEW_AD_TOTAL": {
      const data = action.ad.data;
      return {
        ...state,
        1: data.total,
      };
    }
    case "GET_BLOCKED_AD_TOTAL": {
      const data = action.ad.data;
      return {
        ...state,
        3: data.total,
      };
    }
    default:
      return state;
  }
};
