import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { postLogin } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";
import {
  DTBold13,
  DText13,
  DText15,
  H4Text,
} from "../../ui-kit/typography/Typography.jsx";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../ui-kit/layouts/Stack.jsx";
import { Button } from "../../ui-kit/button/Button.jsx";
import { Input } from "../../ui-kit/input/Input";
import axios from "axios";
import { bloggerUrl } from "../../Actions/consts";
import styled from "styled-components";
import TokenService from "../../services/token.service";

const StyledEyeIcon = styled.div`
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  bottom: 18px;
  right: 16px;
  pointer-events: none;
  background: url("images/icons/ui/forms/eye_off.svg");
  background-size: contain;
  cursor: pointer;
  pointer-events: all;
  opacity: 0.4;
  &.show-password {
    background: url("images/icons/ui/forms/eye.svg");
    background-size: contain;
    opacity: 1;
  }
`;

const ResetPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showFirstPassword, setShowFirstPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const [actualPassword, setActualPassword] = useState({
    value: "",
    error: false,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: false,
  });

  const handlePasswordChange = (e, key) => {
    const value = e.target.value;
    if (key === 0) {
      setActualPassword({ error: false, value });
    } else {
      setNewPassword({ error: false, value });
    }
  };

  useEffect(() => {
    if (!searchParams.get("token")) {
      navigate("/forgot_password");
    }
  }, [searchParams]);

  const handleClick = async () => {
    setLoading(true);
    await axios
      .post(`${bloggerUrl}/api/auth/reset-password`, {
        token: searchParams.get("token"),
        password: newPassword.value,
      })
      .then(async (login) => {
        dispatch({
          type: "HIDE_ALL",
        });
        setReady(true);
      })
      .catch((error) => {
        setActualPassword({ ...actualPassword, error: true });
        setNewPassword({ ...newPassword, error: true });
        dispatch({
          type: "HIDE_ALL",
        });
        console.log(error);
      });
  };
  return (
    <section
      id="auth"
      onKeyPress={(e) =>
        e.key === "Enter" ? sendUser(formFieldErrorState) : null
      }
    >
      {ready ? (
        <div id="auth-form" style={{ paddingBottom: "40px" }}>
          <Stack>
            <img src="images/icons/main/logo.svg" alt="" id="logo" />
            <Cluster justify="center">
              <img
                style={{ width: "112px", marginTop: "56px" }}
                src="images/logo-success.png"
                alt="успешно"
              />
            </Cluster>
            <Stack space="24px" style={{ marginTop: "32px" }}>
              <Cluster justify="center">
                <H4Text>Пароль успешно изменен!</H4Text>
              </Cluster>
              <Button
                size="big"
                full={true}
                variant="primary"
                onClick={() => navigate("/sign_in")}
                text="Войти"
              />
            </Stack>
          </Stack>
        </div>
      ) : (
        <div id="auth-form" style={{ paddingBottom: "26px" }}>
          <Stack>
            <img src="images/icons/main/logo.svg" alt="" id="logo" />
            <Cluster justify="center">
              <H4Text className="title">Придумайте новый пароль</H4Text>
            </Cluster>
          </Stack>
          <Stack space="24px" style={{ marginTop: "24px" }}>
            <div style={{ position: "relative" }}>
              <Input
                type={showFirstPassword ? "text" : "password"}
                id="password-input"
                name="password"
                label="Новый пароль"
                value={actualPassword.value}
                error={actualPassword.error}
                onChange={(e) => handlePasswordChange(e, 0)}
              />
              <StyledEyeIcon
                className={`${showFirstPassword ? "show-password" : ""}`}
                onClick={() => setShowFirstPassword(!showFirstPassword)}
              ></StyledEyeIcon>
            </div>
            <div style={{ position: "relative" }}>
              <Input
                type={showSecondPassword ? "text" : "password"}
                id="password-input"
                name="password"
                label="Подтвердите пароль"
                value={newPassword.value}
                error={newPassword.error}
                onChange={(e) => handlePasswordChange(e, 1)}
              />
              <StyledEyeIcon
                className={`${showSecondPassword ? "show-password" : ""}`}
                onClick={() => setShowSecondPassword(!showSecondPassword)}
              ></StyledEyeIcon>
            </div>
            <Cluster>
              <Button
                className={loading ? "preloader" : ""}
                size="big"
                full={true}
                variant="primary"
                disabled={
                  !newPassword.value.length ||
                  !actualPassword.value.length ||
                  actualPassword.error === true ||
                  newPassword.error === true ||
                  actualPassword.value !== newPassword.value
                }
                onClick={() => handleClick()}
                text="Подтвердить"
              />
            </Cluster>
            <Cluster space="3px" justify="center">
              <DText13 style={{ color: "#94A0B1" }}>
                Вспомнили пароль? Тогда
              </DText13>
              <DTBold13
                style={{ marginTop: "0.12em" }}
                className="register-btn"
                onClick={() => navigate("/sign_in")}
              >
                Войдите
              </DTBold13>
            </Cluster>
          </Stack>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = {
  postLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
