import TokenService from "../../services/token.service";
import { chatDomen } from "../consts";
const socket = new WebSocket(
  `wss://${chatDomen}/ws/notifications?token=${TokenService.getLocalAccessToken()}`
);

export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";
export const RECEIVE_NOTIFICATION = "RECEIVE_NOTIFICATION";

export const receiveMessage = (message) => ({
  type: RECEIVE_NOTIFICATION,
  payload: message,
});

socket.onerror = function (error) {
  console.log(`Ошибка: ${error.message}`);
};

export const startNotificationListening = () => (dispatch) => {
  socket.onopen = function () {
    console.log("Соединение установлено");
  };

  socket.onmessage = function (event) {
    dispatch(receiveMessage(event.data));
  };

  socket.onclose = function (event) {
    console.log("Соединение закрыто");
  };
};
