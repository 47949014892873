const initialVideoProfitGraph = {
  day: {
    video: [],
    shorts: [],
  },
  week: {
    video: [],
    shorts: [],
  },
  month: {
    video: [],
    shorts: [],
  },
  year: {
    video: [],
    shorts: [],
  },
  full: {
    video: [],
    shorts: [],
  },
  video: [],
};

const initialVideoViewGraph = {
  day: {
    video: {
      last: [],
      actual: [],
    },
    shorts: {
      last: [],
      actual: [],
    },
  },
  week: {
    video: {
      last: [],
      actual: [],
    },
    shorts: {
      last: [],
      actual: [],
    },
  },
  month: {
    video: {
      last: [],
      actual: [],
    },
    shorts: {
      last: [],
      actual: [],
    },
  },
  year: {
    video: {
      last: [],
      actual: [],
    },
    shorts: {
      last: [],
      actual: [],
    },
  },
  full: {
    video: {
      last: [],
      actual: [],
    },
    shorts: {
      last: [],
      actual: [],
    },
  },
};
export const VideoProfitGraphReducer = (
  state = initialVideoProfitGraph,
  action
) => {
  switch (action.type) {
    case "GET_VIDEIO_PROFIT_POINT":
      const data = action;
      const videos = [...state[data.period][data.format]];
      videos[data.index] = data.value;
      return {
        ...state,
        [data.period]: { ...state[data.period], [data.format]: videos },
      };
    default:
      return state;
  }
};
export const VideosViewsGraphReducer = (
  state = initialVideoViewGraph,
  action
) => {
  switch (action.type) {
    case "GET_VIDEOS_VIEWS_GRAPH":
      const data = action;
      return {
        ...state,
        [data.period]: {
          ...state[data.period],
          [data.format]: data.views,
        },
      };
    default:
      return state;
  }
};

const initialCurrentVideoProfitGraph = {
  day: [],
  week: [],
  month: [],
  year: [],
  full: [],
};

export const CurrentVideoViewsGraphReducer = (
  state = initialCurrentVideoProfitGraph,
  action
) => {
  switch (action.type) {
    case "GET_VIDEO_VIEWS_GRAPH":
      const data = action;
      return {
        ...state,
        [data.period]: data.views,
      };
    default:
      return state;
  }
};

export const CurrentVideoViewsDepthGraphReducer = (
  state = initialCurrentVideoProfitGraph,
  action
) => {
  switch (action.type) {
    case "GET_VIDEO_VIEWS_DEPTH_GRAPH":
      const data = action;
      return {
        ...state,
        [data.period]: data.views,
      };
    default:
      return state;
  }
};

const initialVideosCountByFormats = [
  {
    id: 0,
    title: "Всего видео:",
    value: 0,
  },
  {
    id: 1,
    title: "Всего Shorts:",
    value: 0,
  },
];

export const VideosCountByFormats = (
  state = initialVideosCountByFormats,
  action
) => {
  switch (action.type) {
    case "GET_VIDEO_FORMAT_COUNT":
      const count = action.video.data.total;
      const formatId = action.formatId;
      const newArray = state.map((elem) =>
        elem.id === formatId ? { ...elem, value: count } : elem
      );
      return newArray;
    default:
      return state;
  }
};
