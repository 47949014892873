import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Authorization from "../components/Index/Authorization.jsx";
import { ModalProvider } from "../components/modal/ModalContext/ModalContext";
import "../../public/fonts.css";
import "./styles/App.scss";
import { PrivateRoute } from "./PrivateRoute.js";
import PersonalArea from "../components/PersonalArea/PersonalArea.jsx";
import { AsideToggleProvider } from "../components/PersonalArea/context/AsideToggleContext.js";
import { getAuthUser } from "../Actions/AuthorizationActions.js";
import Registration from "../components/Index/Registration.jsx";
import { VideoUploadProvider } from "../components/contexts/video-upload-context/VideoUploadContext.jsx";
import ForgotPassword from "../components/Index/ForgotPassword.jsx";
import ResetPassword from "../components/Index/ResetPassword.jsx";
import Policy from "../components/Index/Policy.jsx";
import Legal from "../components/Index/Legal.jsx";
import "../components/Index/policies.scss";

const App = ({ authUser }) => {
  const [creatorState, setCreatorState] = useState();

  useEffect(() => {
    setCreatorState(authUser);
  }, [authUser]);

  return (
    <VideoUploadProvider>
      <AsideToggleProvider>
        <div id="block-on-mobile">
          <img src="images/auth_logo.svg" alt="" id="mobile-logo" />
        </div>
        <Routes>
          <Route
            exact
            path="*"
            element={<PrivateRoute user_id={creatorState} />}
          >
            <Route path="*" element={<PersonalArea user={creatorState} />} />
          </Route>
          <Route exact path="/sign_in" element={<Authorization />} />
          <Route exact path="/sign_up" element={<Registration />} />
          <Route exact path="/forgot_password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/policy" element={<Policy />} />
          <Route exact path="/legal" element={<Legal />} />
        </Routes>
      </AsideToggleProvider>
    </VideoUploadProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    authUser: state.authUser,
  };
};

const mapDispatchToProps = {
  getAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
